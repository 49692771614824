import * as React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { bem } from 'css-util';
import * as Urls from 'Shared/Urls';
import { Loadable, isLoaded } from 'misc/Data/Loadable';
import { State as AppState, DispatchProps } from 'Store';
import { HUDState, HUD } from 'HUD';
import {
  State as AuthState,
  AuthSettings,
  isLoggedIn
} from 'Auth/Data';
import { ActionCreators } from 'Auth/ActionCreator';

import ThemeStyles from 'User/UI/ThemeStyles';
import Spinner from 'Shared/UI/Spinner';
import BackgroundImage from 'Shared/UI/BackgroundImage';
import { DEFAULT_FAVICON } from 'App/HTMLHead';

const CSS = bem('auth-page');

type ConnectedProps = {
  auth: AuthState,
  hud: HUDState
}

type OwnProps = {
  contents: (settings: AuthSettings) => React.ReactNode
}
type Props =
  ConnectedProps & DispatchProps & RouteComponentProps<URLParams> & OwnProps;

interface URLParams {
  programSlug?: string
}

interface State {
  loadingAuthSettings: boolean
}

class AuthPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { loadingAuthSettings: false };
  }

  componentDidMount() {
    if (isLoggedIn(this.props.auth)) {
      this.props.dispatch(push(Urls.rootUrl()));
    } else {
      this.loadAuthSettings();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.programSlug() !== this.programSlug(prevProps)) {
      this.loadAuthSettings();
    }
  }

  loadAuthSettings() {
    if (!this.state.loadingAuthSettings) {
      this.setState(
        { loadingAuthSettings: true },
        () => {
          this.props.dispatch(
            ActionCreators.loadAuthSettings(this.programSlug())
          ).then(() => this.setState({ loadingAuthSettings: false }))
        }
      );
    }
  }

  programSlug(props?: Props): string | undefined {
    props = props || this.props;
    return props.match.params.programSlug || props.auth.programSlug;
  }

  settings(): Loadable<AuthSettings> {
    return this.props.auth.settings;
  }

  backgroundImageUrl(): string | undefined {
    const settings = this.settings();
    if (isLoaded(settings)) {
      return settings.value.backgroundImageUrl;
    }
  }

  render() {
    const settings = this.settings();
    let contents;
    if (isLoaded(settings)) {
      contents = this.props.contents(settings.value);
    } else {
      contents = <Spinner wrap="center" />;
    }

    return (
      <div className={CSS()()}>
        <BackgroundImage src={this.backgroundImageUrl()} />
        {isLoaded(settings) && (
          <>
            <ThemeStyles slug={settings.value.programSlug} />
            {this.renderHtmlHead(settings.value)}
            <div className={CSS('logo-banner')()}>
              <img src={settings.value.logoUrl} />
            </div>
          </>
        )}

        {contents}
        <HUD state={this.props.hud} />
      </div>
    );
  }

  renderHtmlHead(settings: AuthSettings) {
    return (
      <Helmet>
        <title>{settings.htmlTitle}</title>
        {!settings.whiteLabeled &&
         <link rel="shortcut icon" href={DEFAULT_FAVICON} />
        }
      </Helmet>
    )
  }
}

export default withRouter(
  connect<ConnectedProps, DispatchProps, OwnProps, AppState>(
    (state: AppState) => ({ auth: state.auth, hud: state.hud }),
    dispatch => ({ dispatch })
  )(AuthPage)
);
