enum MaybeType {
  Just = 'Just',
  Nothing = 'Nothing'
}

export type Maybe<A>
  = Just<A>
  | Nothing;

export interface Just<A> {
  type: MaybeType.Just,
  value: A
}

export interface Nothing {
  type: MaybeType.Nothing,
  value: undefined
}

const NOTHING: Nothing = { type: MaybeType.Nothing, value: undefined };

// Constructors

export function just<A>(value: A): Maybe<A> {
  return { type: MaybeType.Just, value };
}

export function nothing<A>(): Maybe<A> {
  return NOTHING;
}

export function fromNilable<T>(value: T | undefined | null): Maybe<T> {
  return value === undefined || value === null ? nothing() : just(value);
}

// Predicates

export function isJust<A>(m: Maybe<A>): m is Just<A> {
  return m.type === MaybeType.Just;
}

export function isNothing<A>(m: Maybe<A>): m is Nothing {
  return m.type === MaybeType.Nothing;
}

// Functor/Ap/Monad

export function map<A, B>(fn: (a: A) => B, m: Maybe<A>): Maybe<B> {
  return isJust(m) ? just(fn(m.value)) : nothing();
}

export function apply<A, B>(
  maybeFn: Maybe<(a: A) => B>, a: Maybe<A>
): Maybe<B> {
  if (isJust(maybeFn) && isJust(a)) {
    return just(maybeFn.value(a.value));
  } else {
    return nothing();
  }
}

export function chain<A, B>(fn: (a: A) => Maybe<B>): (a: Maybe<A>) => Maybe<B>;
export function chain<A, B>(fn: (a: A) => Maybe<B>, a: Maybe<A>): Maybe<B>;
export function chain<A, B>(fn: (a: A) => Maybe<B>, a?: Maybe<A>) {
  if (a !== undefined) {
    return chain(fn)(a);
  } else {
    return (val: Maybe<A>) => isJust(val) ? fn(val.value) : nothing();
  }
}

export const pure = just;

// Other functions

export function exec<A>(fn: (a: A) => any): (m: Maybe<A>) => Maybe<A>;
export function exec<A>(fn: (a: A) => any, m: Maybe<A>): Maybe<A>;
export function exec<A>(fn: (a: A) => any, m?: Maybe<A>) {
  if (m !== undefined) {
    return exec(fn)(m);
  } else {
    return (m2: Maybe<A>) => { map(fn, m2); return m2; }
  }
}

export function fromMaybe<A>(def: A, m: Maybe<A>): A {
  return isJust(m) ? m.value : def;
}

export function asNilable<A>(m: Maybe<A>): A | undefined {
  return m.value;
}

export function maybe<A, B>(def: () => B, fn: (a: A) => B, m: Maybe<A>): B {
  return isJust(m) ? fn(m.value) :  def();
}

export function both<A, B>(ma: Maybe<A>, mb: Maybe<B>): Maybe<[A, B]> {
  return chain(
    a => chain(
      b => just([a, b] as [A, B]), mb
    ), ma
  );
}

export function or<A>(...maybes: Maybe<A>[]): Maybe<A> {
  return maybes.filter(isJust)[0] || nothing();
}
