import * as EventEmitter from 'eventemitter3';
import * as BLE from 'BLE/Data';
import { makeProtocol } from './Protocol';
import NordicDFU from 'BLE/Protocols/NordicDFU/DFU';
import NordicDFUScanner from 'BLE/Protocols/NordicDFU/Scanner';

interface DFUInterface {
  update(rawPackageData: ArrayBuffer): Promise<void>

  onProgress(fn: (progress: number) => void): void
}

enum Events {
  PROGRESS = 'PROGRESS'
}

export class DFU implements DFUInterface {
  private dfuScanner: NordicDFUScanner;
  private events: EventEmitter<Events>;

  constructor(
    private readonly device: BLE.Device,
    private readonly bluetooth: BluetoothlePlugin.Bluetoothle
  ) {
    this.dfuScanner = new NordicDFUScanner(bluetooth);
    this.events = new EventEmitter();
  }

  public onProgress(fn: (progress: number) => void) {
    this.events.on(Events.PROGRESS, fn)
  }

  public async update(rawPackageData: ArrayBuffer) {
    let dfu: NordicDFU | undefined;
    this.log('Searching for existing DFU device');
    dfu = await this.findDFU(5000).catch(() => undefined);

    if (!dfu) {
      this.log('Could not find DFU device, Connecting to application instead.');
      const jstyle = makeProtocol(this.bluetooth, this.device);
      await jstyle.init();
      this.log('Entering DFU mode via the application.');
      await jstyle.enterDFUMode();
      this.log('Finding DFU device');
      dfu = await this.findDFU();
    }

    try {
      this.log('Running DFU');
      await dfu.update(rawPackageData);
      this.log('Complete!');
    } catch(e) {
      this.log('error!:', e);
    }
  }

  private async findDFU(timeout=5000): Promise<NordicDFU> {
    const dev = await this.dfuScanner.scan(timeout);
    const dfu = new NordicDFU(dev, this.bluetooth);
    dfu.onProgress(p => this.events.emit(Events.PROGRESS, p));
    return dfu;
  }

  private log(...messages: any[]) {
    console.log(...messages);
  }
}
