import {
  AuthSettingsJSON,
  NativeAppLinkJSON,
  NativeAppPlatformJSON
} from 'Api/JSON/Auth';

import {
  AuthSettings,
  NativeAppLink,
  NativeAppPlatform
} from './Data';

export function authSettingsFromJSON(
  json: AuthSettingsJSON
): AuthSettings {
  return {
    helpHtml: json.help_html,
    logoUrl: json.logo_url,
    backgroundImageUrl: json.background_image_url,
    programName: json.program_name,
    programSlug: json.program_slug,
    publicSignup: json.public_signup,
    tagline: json.tagline,
    nativeApps: json.native_apps.map(nativeAppFromJSON),
    termsUrl: json.terms_url,
    privacyUrl: json.privacy_url,
    whiteLabeled: json.white_labeled,
    htmlTitle: json.html_title
  };
}

function nativeAppFromJSON(json: NativeAppLinkJSON): NativeAppLink {
  return {
    platform: nativeAppPlatformFromJSON(json.platform),
    downloadUrl: json.download_url
  };
}

function nativeAppPlatformFromJSON(
  json: NativeAppPlatformJSON
): NativeAppPlatform {
  switch(json) {
  case 'android':
    return NativeAppPlatform.ANDROID;
  case 'ios':
    return NativeAppPlatform.IOS;
  }
}
