import * as React from 'react';
import * as moment from 'moment-timezone';

import { connect } from 'react-redux';
import { State, DispatchProps } from 'Store';

import { ActionCreators } from 'CarePlans/ActionCreator';
import { ActionCreators as TasksAC, TaskId } from 'Tasks';
import { Loadable, fromLoadable } from 'misc/Data/Loadable';
import { Maybe, maybe } from 'misc/Data/Maybe';
import NoContent from 'misc/UI/NoContent';
import { State as CarePlansState, DailyStatus, ActionId } from 'CarePlans/Data';
import ActionStatusList from 'CarePlans/UI/ActionStatusList';
import { HUD } from 'HUD';

interface OwnProps {
  date: moment.Moment
}

interface ConnectedProps {
  carePlans: CarePlansState
}

type Props = ConnectedProps & DispatchProps & OwnProps;

class ConnectedActionStatusList extends React.Component<Props, {}> {
  componentDidMount() {
    this.loadDailyStatus();
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.date.isSame(this.props.date, 'day')) {
      this.loadDailyStatus();
    }
  }

  clearDailyStatus() {
    this.props.dispatch(ActionCreators.clearActionStatuses());
  }

  loadDailyStatus = () => {
    this.props.dispatch(ActionCreators.loadActionStatuses(this.props.date));
  }

  completeTask = async (taskId: TaskId, time: moment.Moment) => {
    this.clearDailyStatus();
    await this.props.dispatch(TasksAC.completeTaskAtTime(taskId, time));
    this.loadDailyStatus();
  }

  undoTaskAction = async (actionId: ActionId) => {
    this.clearDailyStatus();
    await this.props.dispatch(
      ActionCreators.undoTaskAction(this.props.date, actionId)
    );
    this.loadDailyStatus();
  }

  dailyStatus(): Loadable<Maybe<DailyStatus>> {
    return this.props.carePlans.dailyStatus;
  }

  render() {
    return fromLoadable(
      status => this.renderMaybeDailyStatus(status),
      () => this.renderLoading(),
      this.dailyStatus()
    );
  }

  renderLoading() {
    return <HUD state="loading" />;
  }

  renderMaybeDailyStatus(maybeStatus: Maybe<DailyStatus>) {
    return maybe(
      () => this.renderNoCarePlan(),
      status => this.renderDailyStatus(status),
      maybeStatus
    );
  }

  renderNoCarePlan() {
    const msg =
      "There are no care tasks to display. Please talk to your care team to " +
      "activate care tasks.";
    return <NoContent message={msg}/>;
  }

  renderDailyStatus(status: DailyStatus) {
    return (
      <ActionStatusList
        status={status}
        completeTask={this.completeTask}
        undoTaskAction={this.undoTaskAction}
        reload={this.loadDailyStatus}
      />
    );
  }
}

export default connect<ConnectedProps, DispatchProps, OwnProps, State>(
  (state: State) => ({ carePlans: state.carePlans })
)(ConnectedActionStatusList);
