import * as React from 'react';
import { Comment } from 'Comments/Data';
import Logo from 'Shared/UI/Logo';
import { safeLink } from 'Shared/SafeLink';

interface Props {
  comments: Comment[],
  whiteLabeled?: boolean
}

const CommentThread = (props: Props) => {
  return (
    <ul className="comment-thread">
      {props.comments.map((c, i) =>
        <CommentThreadItem
          comment={c}
          whiteLabeled={props.whiteLabeled}
          key={i}
        />
      )}
    </ul>
  );
}

export default CommentThread;

interface CommentThreadItemProps {
  comment: Comment,
  whiteLabeled?: boolean
}

const CommentThreadItem = (props: CommentThreadItemProps) => {
  const { comment } = props;
  return (
    <li className="clearfix comment-thread__comment">
      <div className="comment-thread__left">
        {commentPic(props)}
      </div>
      <div className="comment-thread__right">
        <div className="comment-thread__byline">
          <span className="comment-thread__poster-name">
            {commentPostedBy(props)}
          </span>
          <time className="comment-thread__post-time">
            {comment.postedAt.format('h:mm A M/D/YY')}
          </time>
        </div>
        <p className="comment-thread__text"
          dangerouslySetInnerHTML={{__html: safeLink(comment.text)}}>
        </p>
      </div>
    </li>
  )
}

function commentPostedBy(props: CommentThreadItemProps) {
  const { comment, whiteLabeled } = props;
  if (!comment.system || !whiteLabeled) {
    return props.comment.postedBy;
  }
}

function commentPic(props: CommentThreadItemProps) {
  const { comment, whiteLabeled } = props;
  if (comment.picUrl) {
    return (
      <img src={comment.picUrl} className="comment-thread__poster-pic" />
    );
  } else {
    return (
      <span className="comment-thread__poster-pic">
        {!comment.system || !whiteLabeled ? <Logo /> : ''}
      </span>
    );
  }
}
