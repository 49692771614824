import * as R from 'ramda';
import * as moment from 'moment-timezone';
import { IconName } from 'Shared/UI/Icon';
import * as Edge from 'Edge/Data';
import { profPicUrl } from 'Shared/ProfPic'

import {
  BiopostJSON, ScoreJSON, PropJSON, ActivityJSON, SpecUpdateJSON,
  StandardSpecUpdateJSON, HeightSpecUpdateJSON, BloodPressureSpecUpdateJSON,
  ActivityCategoryJSON, GetActivitiesJSON, RecommendedActivityJSON,
  CommentJSON
} from 'Api/JSON/Post'

import { isoTimeToJSON } from 'Shared/JSON';

import {
  SpecUpdate, StandardSpecUpdate, HeightSpecUpdate, BloodPressureSpecUpdate,
  isStandardSpecUpdate, isHeightSpecUpdate, isBloodPressureSpecUpdate,
  specTypeToJSON
} from 'Specs';

import {
  Biopost, Score, Prop, Activity, Category, CategoryInfo, RecommendedActivity,
  Comment
} from './Data';

/*------------------------------------------------------------*/

export function biopostFromJSON(json: BiopostJSON): Biopost {
  return {
    id: json.id,
    title: json.title,
    newScore: scoreFromJSON(json.new_score),
    oldScore: json.old_score && scoreFromJSON(json.old_score),
    scoreIncrement: json.score_increment,
    props: json.props.map(propFromJSON),
    userId: json.user_id,
    userName: json.user_name,
    time: moment(json.timestamp),
    icon: iconFromJSON(json.icon),
    commentCount: json.comment_count,
    source: json.source
  };
}

export function dynamicBiopostFromJSON(json: BiopostJSON): Biopost {
  return { ...biopostFromJSON(json), dynamic: true };
}

export function latestBiopostFromJSON(json: BiopostJSON): Biopost {
  return { ...biopostFromJSON(json), dynamic: json.is_dynamic };
}

function scoreFromJSON(json: ScoreJSON): Score {
  return {
    value: json.value,
    time: moment(json.time)
  }
}

function propFromJSON(json: PropJSON): Prop {
  return { giverId: json.giver_id };
}

export function activityFromJSON(json: ActivityJSON): Activity {
  return { ...json, loading: false };
}


export function specUpdateToJSON(update: SpecUpdate): SpecUpdateJSON {
  if (isStandardSpecUpdate(update)) {
    return standardSpecUpdateToJSON(update);
  } else if (isHeightSpecUpdate(update)) {
    return heightSpecUpdateToJSON(update);
  } else if (isBloodPressureSpecUpdate(update)) {
    return bloodPressureSpecUpdateToJSON(update);
  } else {
    throw new Error(
      'Unhandled case in specUpdateToJSON: ' + JSON.stringify(update)
    );
  }
}

function standardSpecUpdateToJSON(
  update: StandardSpecUpdate
): StandardSpecUpdateJSON {
  return {
    spec: specTypeToJSON(update.spec),
    value: update.value,
    time: update.time ? isoTimeToJSON(update.time) : undefined
  };
}

function heightSpecUpdateToJSON(
  update: HeightSpecUpdate
): HeightSpecUpdateJSON {
  return {
    spec: 'height',
    height_feet: update.heightFeet,
    height_inches: update.heightInches,
    time: update.time ? isoTimeToJSON(update.time) : undefined
  };
}

function bloodPressureSpecUpdateToJSON(
  update: BloodPressureSpecUpdate
): BloodPressureSpecUpdateJSON {
  return {
    spec: 'blood_pressure',
    systolic_bp: update.systolic,
    diastolic_bp: update.diastolic,
    time: update.time ? isoTimeToJSON(update.time) : undefined
  };
}

function categoryFromJSON(json: ActivityCategoryJSON): Category {
  return {
    name: json.name,
    icon: iconFromJSON(json.icon),
    topActivityIds: json.top_activities.map(a => a.id),
    bottomActivityIds: json.bottom_activities.map(a => a.id)
  };
}

type ExtractedActivityData = {
  categories: Category[],
  activities: Activity[],
  favorites: CategoryInfo | undefined,
  favoritesActivities: Activity[],
  genius: CategoryInfo | undefined,
  geniusRecommendations: RecommendedActivity[],
  specsCategoryName: string | undefined
};

export function extractActivityDataFromJSON(
  json: GetActivitiesJSON
): ExtractedActivityData {
  const init: {categories: Category[], activities: Activity[]} =
    {categories: [], activities: []};

  let data = json.categories.reduce(
    (result, categoryJSON) => {
      const activities =
        result.activities.concat(activitiesFromCategoryJSON(categoryJSON));
      const category = categoryFromJSON(categoryJSON);

      return {
        activities: R.uniqBy(
          (activity) => { return R.prop('id', activity) },
          activities
        ),
        categories: R.append(
          category,
          result.categories
        )
      };

    },
    init
  )

  let favorites: CategoryInfo | undefined = undefined;
  if (json.favorites) {
    favorites = {
      name: json.favorites.name, icon: iconFromJSON(json.favorites.icon)
    };
  }

  const favoritesActivities =
    json.favorites ?
    activitiesFromCategoryJSON(json.favorites) : [];

  let genius: CategoryInfo | undefined = undefined;
  if (json.genius) {
    genius = {
      name: json.genius.name, icon: iconFromJSON(json.genius.icon)
    };
  }

  const geniusRecommendations =
    json.genius ?
    json.genius.activities.map(recommendedActivityFromJSON) : [];

  return {
      ...data,
    specsCategoryName: json.specs_category_name,
    favorites,
    favoritesActivities,
    genius,
    geniusRecommendations
  };
}

function iconFromJSON(json: string): IconName {
  return json as IconName;
}

function activitiesFromCategoryJSON(json: ActivityCategoryJSON): Activity[] {
  return R.concat(
    json.top_activities.map(activityFromJSON),
    json.bottom_activities.map(activityFromJSON)
  );
}

export function recommendedActivityFromJSON(
  json: RecommendedActivityJSON
): RecommendedActivity {
  return {
    id: json.id,
    name: json.name,
    scoreChange: json.score_change
  };
}

export function commentFromJSON(
  providers: Edge.Provider[], json: CommentJSON
): Comment {
  const postedAt = moment(json.posted_at);
  const provider =
    json.source && Edge.getProviderFromList(providers, json.source);
  if (json.system || (json.source && !provider)) {
    return {
      system: true,
      postedBy: '1bios',
      picUrl: undefined,
      text: json.text,
      postedAt
    };
  } else if (json.source && provider) {
    return {
      system: false,
      postedBy: provider.label,
      picUrl: provider.iconUrl,
      text: json.text,
      postedAt
    };
  } else {
    return {
      system: false,
      postedBy: json.user_name || '',
      picUrl: profPicUrl(json.user_id || ''),
      text: json.text,
      postedAt
    };
  }
}
