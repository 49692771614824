import * as moment from 'moment-timezone';
import * as M from 'misc/Data/Maybe';
import * as R from 'ramda';
import { UserId } from '1bios/User/Data';
import { SortDir } from 'misc/UI/DataTable/Data';
export type NSType = 'TRENDS';
export const NS: NSType = 'TRENDS';

export enum MetricKey {
  A1C = 'A1C',
  BLOOD_PRESSURE = 'BLOOD_PRESSURE',
  BMI = 'BMI',
  BODY_FAT = 'BODY_FAT',
  CHOLESTEROL = 'CHOLESTEROL',
  CHOLESTEROL_RATIO = 'CHOLESTEROL_RATIO',
  DIASTOLIC_BP = 'DIASTOLIC_BP',
  GLUCOSE = 'GLUCOSE',
  HDL_CHOLESTEROL = 'HDL_CHOLESTEROL',
  LDL_CHOLESTEROL = 'LDL_CHOLESTEROL',
  PHQ2 = 'PHQ2',
  PHQ9 = 'PHQ9',
  RESTING_HEART_RATE = 'RESTING_HEART_RATE',
  CONTINUOUS_HEART_RATE = 'CONTINUOUS_HEART_RATE',
  SYSTOLIC_BP = 'SYSTOLIC_BP',
  WAIST = 'WAIST',
  WEIGHT = 'WEIGHT',
  SLEEP = 'SLEEP',
  STEPS = 'STEPS',
  ACTIVE_MINUTES = 'ACTIVE_MINUTES',
  GAD2 = 'GAD2',
  GAD7 = 'GAD7',
  KATZ_ADL = 'KATZ_ADL',
  TYPE2_DIABETES_RISK = 'TYPE2_DIABETES_RISK',
  DAST10_SCORE = 'DAST10_SCORE',
  SPO2 = 'SPO2',
  BODY_TEMP = 'BODY_TEMP',
  HRV = 'HRV',
  RESPIRATORY_RATE = 'RESPIRATORY_RATE',
  STRESS_SCORE = 'STRESS_SCORE',
  PEF = 'PEF',
  FEV1 = 'FEV1'
}

export interface Metric {
  label: string
  key: MetricKey
}

export enum TrendWindow {
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year'
}

export interface DataRequest {
  userId: UserId,
  window: TrendWindow,
  key: MetricKey,
  date: moment.Moment
}

export interface TrendData {
  startDate: moment.Moment,
  endDate: moment.Moment,
  datasets: Dataset[]
}

export interface Dataset {
  key: MetricKey,
  points: DataPoint[],
  units: string,
  min: number,
  max: number,
  stepSize?: number,
  goal?: number
  label: string
}

export interface DataPoint {
  time: moment.Moment,
  value: number
}

export interface BloodPressure {
  systolic: number,
  diastolic: number
}

type BpPhase = 'systolic' | 'diastolic';

export function metricKeyFromString(key: string): M.Maybe<MetricKey> {
  if (key in MetricKey) {
    return M.just(key as MetricKey);
  } else {
    return M.nothing();
  };
}

export function trendDataIsEmpty(data: TrendData): boolean {
  const firstDs = data.datasets[0];
  if (!firstDs) { return true; }
  return firstDs.points.length === 0;
}

export function windowFromString(def: TrendWindow, str: string): TrendWindow {
  switch(str) {
  case TrendWindow.WEEK:
    return TrendWindow.WEEK;
  case TrendWindow.MONTH:
    return TrendWindow.MONTH;
  case TrendWindow.YEAR:
    return TrendWindow.YEAR;
  default:
    return def;
  }
}

export function nextDate(
  date: moment.Moment, window: TrendWindow
): moment.Moment {
  return date.clone().add(1, window);
}

export function previousDate(
  date: moment.Moment, window: TrendWindow
): moment.Moment {
  return date.clone().subtract(1, window);
}

export function formatPeriod(date: moment.Moment, window: TrendWindow): string {
  switch(window) {
  case TrendWindow.MONTH:
    return date.format('MMMM YYYY');
  case TrendWindow.YEAR:
    return date.format('YYYY');
  case TrendWindow.WEEK:
    return 'Week ' + date.format('W') + ' of ' + date.format('GGGG');
  }
}

export function formatValueWithMetricKey(
  key: MetricKey,
  yValue: string | number | undefined
): string {
  if (yValue === undefined) { return ''; }
  switch(key) {
    case MetricKey.SLEEP:
      return formatSleepValue(parseFloat(yValue.toString()));
    case MetricKey.STEPS:
    case MetricKey.ACTIVE_MINUTES:
    case MetricKey.CONTINUOUS_HEART_RATE:
    case MetricKey.RESTING_HEART_RATE:
    case MetricKey.BLOOD_PRESSURE:
    case MetricKey.SYSTOLIC_BP:
    case MetricKey.DIASTOLIC_BP:
    case MetricKey.HRV:
    case MetricKey.STRESS_SCORE:
    case MetricKey.RESPIRATORY_RATE:
    case MetricKey.HDL_CHOLESTEROL:
    case MetricKey.LDL_CHOLESTEROL:
    case MetricKey.PHQ9:
    case MetricKey.PHQ2:
    case MetricKey.GAD2:
    case MetricKey.GAD7:
    case MetricKey.KATZ_ADL:
    case MetricKey.TYPE2_DIABETES_RISK:
    case MetricKey.DAST10_SCORE:
    case MetricKey.SPO2:
      return formatWithComma(parseInt(yValue.toString()));
    case MetricKey.BMI:
    case MetricKey.CHOLESTEROL_RATIO:
    case MetricKey.BODY_TEMP:
    case MetricKey.BODY_FAT:
    case MetricKey.PEF:
    case MetricKey.FEV1:
      return formatNumberToOneDecimal(parseFloat(yValue.toString()));
    default:
      return yValue.toString();
  }
}

export function calculateAveragesForBloodPressure(
  systolicDataset: Dataset, diastolicDataset: Dataset
): BloodPressure {
  return {
    systolic: calculateAverageForDataset(systolicDataset),
    diastolic: calculateAverageForDataset(diastolicDataset)
  };
}

export function calculateAverageForDataset(
  ds: Dataset, opts={ round: true }
): number {
  const points = R.map((pt: DataPoint) => pt.value, ds.points);
  const average = R.mean(points);
  return opts.round ? Math.round(average) : average;
}

export function calculateAverageForSteps(
  ds: Dataset, selectedWindow: TrendWindow, selectedDate: moment.Moment
): number {
  const points = R.map(pt => pt.value, ds.points);
  const days = numberOfDaysInWindow(selectedWindow, selectedDate);
  return Math.round(R.sum(points) / days);
}

export function calculateMinForDataset(
  ds: Dataset, opts={ round: true }
): number {
  const points: number[] = R.map((pt: DataPoint) => pt.value, ds.points);
  const min = Math.min(...points);
  return opts.round ? Math.round(min) : min;
}

export function calculateMinForBloodPressure(
  systolicDataset: Dataset, diastolicDataset: Dataset, phase: BpPhase
): BloodPressure {
  return calculateMinOrMax('min', systolicDataset, diastolicDataset, phase);
}

export function calculateMaxForDataset(
  ds: Dataset, opts={ round: true }
): number {
  const points: number[] = R.map((pt: DataPoint) => pt.value, ds.points);
  const max = Math.max(...points);
  return opts.round ? Math.round(max) : max;
}

export function calculateMaxForBloodPressure(
  systolicDataset: Dataset, diastolicDataset: Dataset, phase: BpPhase
): BloodPressure {
  return calculateMinOrMax('max', systolicDataset, diastolicDataset, phase);
}

export function numberOfDaysInWindow(
  window: TrendWindow, date: moment.Moment
): number {
  const windowEndDate = date.clone();
  const startDate: moment.Moment = date.clone();
  switch(window) {
    case TrendWindow.WEEK:
      startDate.startOf('isoWeek');
      windowEndDate.endOf('isoWeek');
      break;
    case TrendWindow.MONTH:
      startDate.startOf('month');
      windowEndDate.endOf('month');
      break;
    case TrendWindow.YEAR:
      startDate.startOf('year');
      windowEndDate.endOf('year');
      break;
  }
  const endDate =
    moment.min([moment(), windowEndDate]).add(1, 'day').startOf('day');
  return endDate.diff(startDate, 'days');
}

export function systolicDs(datasets: Dataset[]): Dataset | undefined {
  return R.find((ds: Dataset) => ds.key === MetricKey.SYSTOLIC_BP, datasets);
}

export function diastolicDs(datasets: Dataset[]): Dataset | undefined {
  return R.find((ds: Dataset) => ds.key === MetricKey.DIASTOLIC_BP, datasets);
}

/**
 * hoursDecimal is a decimal number of hours, so for example, 4.5 would be 4
 * hours and 30 minutes.
 */
function formatSleepValue(hoursDecimal: number): string {
  const totalMinutes = Math.round(hoursDecimal * 60);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours}h ${minutes}m`;
}

function formatNumberToOneDecimal(val: number): string {
  return val.toFixed(1);
}

function formatWithComma(val: number): string {
  return val.toLocaleString();
}

function mergeBloodPressure(
  systolicDataset: Dataset, diastolicDataset: Dataset
): BloodPressure[] {
  const values = R.map(R.prop('value'));
  return R.zipWith((sy: number, ds: number) => {
      return {systolic: sy, diastolic: ds};
    },
    values(systolicDataset.points),
    values(diastolicDataset.points)
  );
}

function calculateMinOrMax(
  aggregate: 'min' | 'max',
  systolicDataset: Dataset,
  diastolicDataset: Dataset,
  phase: BpPhase
): BloodPressure {
  const bloodPressures = mergeBloodPressure(systolicDataset, diastolicDataset)
  const greaterThanOrLessThan = aggregate === 'min' ? R.lt : R.gt
  const compare =
      (acc: BloodPressure, bp: BloodPressure) => {
        if (greaterThanOrLessThan(bp[phase], acc[phase])) {
          return bp;
        } else {
          return acc;
        }
      }

  return R.reduce(compare, bloodPressures[0], bloodPressures);
}

//Trends Table defaults
export const defaultSortKey = 'date';
export const defaultSortDir: SortDir = 'asc';
export const defaultPerPage = 50;
