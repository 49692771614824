import * as R from 'ramda';
import * as React from 'react';

import { Specs, ExpandedSpecType, SpecUpdate } from '../Data';

import SingleValue from './SingleValueSpecForm';
import BloodPressure from './BloodPressureForm';
import Height from './HeightForm';
import BMI from './BMIForm';

interface Props {
  specs: Specs,
  show: ExpandedSpecType[],
  submitSpecUpdate(specUpdate: SpecUpdate): void
}

export default function renderSpecForms(
  props: Props
): (React.ReactElement<Props>) {
  const specs = props.specs;
  const submitSpecUpdate = submit(props);

  const show = (s: ExpandedSpecType) =>
    props.show === undefined || R.contains(s, props.show);

  return (
    <div className="measurement-forms-container">
      <div className="measurement-forms">
        { show('heightFeet') || show('heightInches') || show('height') ?
          <Height
            heightFeet={specs.heightFeet}
            heightInches={specs.heightInches}
            submit={submitSpecUpdate}>
          </Height> : null
        }
        { show('weight') ?
          <SingleValue
            spec="weight"
            value={specs.weight}
            submit={submitSpecUpdate}>
          </SingleValue> : null
        }

        {show('bmi') ? <BMI bmi={specs.bmi}/> : null}

        { show('systolicBp') || show('diastolicBp') || show('bloodPressure') ?
          <BloodPressure
            systolic={specs.systolicBp}
            diastolic={specs.diastolicBp}
            submit={submitSpecUpdate}>
          </BloodPressure> : null
        }

        { show('restingHeartRate') ?
          <SingleValue
            spec="restingHeartRate"
            value={specs.restingHeartRate}
            submit={submitSpecUpdate}>
          </SingleValue> : null
        }

        { show('hrv') &&
          <SingleValue
            spec="hrv"
            value={specs.hrv}
            submit={submitSpecUpdate}
          />
        }

        { show('respiratoryRate') &&
          <SingleValue
            spec="respiratoryRate"
            value={specs.respiratoryRate}
            submit={submitSpecUpdate}
          />
        }

        { show('spo2') ?
          <SingleValue
            spec="spo2"
            value={specs.spo2}
            submit={submitSpecUpdate}
          /> : null
        }

        {
          show('bodyTemp') ?
          <SingleValue
            spec="bodyTemp"
            value={specs.bodyTemp}
            submit={submitSpecUpdate}
          /> : null
        }

        { show('glucose') ?
          <SingleValue
            spec="glucose"
            value={specs.glucose}
            submit={submitSpecUpdate}
          >
          </SingleValue> : null
        }

        { show('a1c') ?
          <SingleValue
            spec="a1c"
            value={specs.a1c}
            submit={submitSpecUpdate}
          >
          </SingleValue> : null
        }

        { show('waist') ?
          <SingleValue
            spec="waist"
            value={specs.waist}
            submit={submitSpecUpdate}>
          </SingleValue> : null
        }

        { show('bodyFat') ?
          <SingleValue
            spec="bodyFat"
            value={specs.bodyFat}
            submit={submitSpecUpdate}>
          </SingleValue> : null
        }

        { show('ldlCholesterol') ?
          <SingleValue
            spec="ldlCholesterol"
            value={specs.ldlCholesterol}
            submit={submitSpecUpdate}>
          </SingleValue> : null
        }

        { show('hdlCholesterol') ?
          <SingleValue
            spec="hdlCholesterol"
            value={specs.hdlCholesterol}
            submit={submitSpecUpdate}>
          </SingleValue> : null
        }

        { show('stressScore') &&
          <SingleValue
            spec="stressScore"
            value={specs.stressScore}
            submit={submitSpecUpdate}
          />
        }

        { show('fev1') &&
          <SingleValue
            spec="fev1"
            value={specs.fev1}
            submit={submitSpecUpdate}
          />
        }

        { show('pef') &&
          <SingleValue
            spec="pef"
            value={specs.pef}
            submit={submitSpecUpdate}
          />
        }
      </div>
    </div>
  );
}

const submit = R.curry(_submit);
function _submit(props: Props, specUpdate: SpecUpdate): void {
  props.submitSpecUpdate(specUpdate);
}
