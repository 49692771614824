import * as moment from 'moment-timezone';
import { WithNS, withNS as originalWithNS } from 'Shared/NSAction';
import { NS, NSType, DailyStatus } from './Data'

export enum ActionType {
  CLEAR_ACTION_STATUSES = 'CLEAR_ACTION_STATUSES',
  LOAD_ACTION_STATUSES__BEGIN = 'LOAD_ACTION_STATUSES__BEGIN',
  LOAD_ACTION_STATUSES__SUCCESS = 'LOAD_ACTION_STATUSES__SUCCESS',
  LOAD_ACTION_STATUSES__ERROR = 'LOAD_ACTION_STATUSES__ERROR',
}

export type Action
  = ClearActionStatuses
  | LoadActionStatuses_Begin
  | LoadActionStatuses_Success
  | LoadActionStatuses_Error;

interface ClearActionStatuses {
  type: ActionType.CLEAR_ACTION_STATUSES
}

interface LoadActionStatuses_Success {
  type: ActionType.LOAD_ACTION_STATUSES__SUCCESS,
  dailyStatus: DailyStatus
}

interface LoadActionStatuses_Error {
  type: ActionType.LOAD_ACTION_STATUSES__ERROR
}

interface LoadActionStatuses_Begin {
  type: ActionType.LOAD_ACTION_STATUSES__BEGIN,
  date: moment.Moment
}

export type NSAction = WithNS<NSType, Action>;
export const withNS = originalWithNS<NSType, Action>(NS);
