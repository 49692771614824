import * as EventEmitter from 'eventemitter3';
import * as moment from 'moment-timezone'
import { makeLogger } from 'Shared/Log';
import { Device } from 'BLE/Data';
import { makeDeviceAccess } from 'BLE/DeviceAccess'
import * as Timeout from 'Shared/Timeout';
import * as Version from 'Shared/Data/Version';
import * as CRC from './CRC';
import * as Data from './Data';
import { DeviceFamily } from './Data';
import * as Codings from './Codings';

// UUIDs
const SERVICE = 'FFF0';
const READ_CHAR = 'FFF7';
const WRITE_CHAR = 'FFF6';

// Command octets
enum CMD {
  GET_VERSION = 0x27,
  GET_TIME = 0x41,
  SET_TIME = 0x01,
  GET_DEVICE_SETTINGS = 0x04,
  SET_DEVICE_SETTINGS = 0x03,
  GET_AUTO_MONITORING = 0x2B,
  SET_AUTO_MONITORING = 0x2A,
  GET_BATTERY = 0x13,
  GET_DAILY_SUMMARIES = 0x51,
  GET_DAILY_DETAILS = 0x52,
  GET_HR_DATA = 0x55,
  GET_HRV_DATA = 0x56,
  GET_SPO2 = 0x60,
  GET_BODY_TEMPS = 0x65,
  GET_SLEEP_DETAILS = 0x53,
  GET_BP_DATA = 0x67,
  SET_BP_CALIBRATION = 0x69,
  GET_BP_CALIBRATION = 0x6c,
  ENTER_DFU_MODE = 0x47
}

enum PageCMD {
  LATEST = 0,
  NEXT = 2,
  SPECIFIC = 1
}

enum ChangeEvent {
  STATE = 'change',
  VERSION = 'change:version',
  DEVICE_SETTINGS = 'change:deviceSettings',
  TIME = 'change:time',
  BATTERY = 'change:battery',
  DAILY_SUMMARIES = 'change:dailySummaries',
  DAILY_DETAILS = 'change:dailyDetails',
  HRS = 'change:hrs',
  HRVS = 'change:hrvs',
  SPO2S = 'change:spo2s',
  BODY_TEMPS = 'change:bodyTemps',
  SLEEP_DETAILS = 'change:sleepDetails',
  AUTO_MONITORING = 'change:autoMonitoring',
  BPS = 'change:bps',
  BP_CALIBRATION = 'change:bpCalibration'
}

enum DoneEvent {
  DISCONNECT = 'disconnect',
  SET_TIME = 'done:setTime',
  SET_AUTO_MONITORING = 'done:setAutoMonitoring',
  SET_DEVICE_SETTINGS = 'done:setDeviceSettings',
  SET_BP_CALIBRATION = 'done:setBPCalibration',
  ENTER_DFU_MODE = 'done:enterDFUMode'
}

type Event = ChangeEvent | DoneEvent;

interface IntervalicMonitoringOpts {
  minutes: number,
  startHour?: number,
  startMinute?: number,
  endHour?: number,
  endMinute?: number
}
const DEFAULT_INTERVALIC_MONITORING_OPTS: IntervalicMonitoringOpts = {
  minutes: 15,
  startHour: 0,
  startMinute: 0,
  endHour: 23,
  endMinute: 59
}

export interface JStyle1963YHProtocol {
  onChange: (fn: (state: Data.State) => void) => void,
  onChangeHRVs: (fn: (hrvs: Data.HRV[]) => void) => void,
  onChangeHRs: (fn: (hrs: Data.HR[]) => void) => void,
  onChangeSpO2s: (fn: (spo2s: Data.SpO2[]) => void) => void,
  onChangeBodyTemps: (fn: (bodyTemps: Data.BodyTemp[]) => void) => void,
  onChangeDailySummaries:
    (fn: (summaries: Data.DailyActivitySummary[]) => void) => void,
  onChangeSleepDetails: (fn: (details: Data.SleepDetail[]) => void) => void,
  onChangeBPs: (fn: (bps: Data.BP[]) => void) => void,

  getState: () => Data.State,
  init: () => Promise<void>,
  close: () => Promise<void>,

  supportsAutoMonitoring:
    (dataType: Data.AutoMonitoringType) => Promise<boolean>,
  supportsBPCalibration: () => Promise<boolean>,
  getVersion: () => Promise<void>,
  getTime: () => Promise<void>,
  setTime: (time?: moment.Moment) => Promise<void>,
  getDeviceSettings: () => Promise<void>,
  setDeviceSettings: (settings: Data.DeviceSettings) => Promise<void>,
  getAutoMonitoring: (dataType: Data.AutoMonitoringType) => Promise<void>,
  setAutoHRMonitoring: (opts: IntervalicMonitoringOpts) => Promise<void>,
  setAutoBPMonitoring: (opts: IntervalicMonitoringOpts) => Promise<void>,
  setAutoSpo2Monitoring: (opts: IntervalicMonitoringOpts) => Promise<void>,
  setAutoBodyTempMonitoring: (opts: IntervalicMonitoringOpts) => Promise<void>,
  getBattery: () => Promise<void>,
  getDailySummaries: () => Promise<void>,
  getDailyDetails: (pageCmd?: PageCMD) => Promise<void>,
  getHRData: (pageCmd?: PageCMD) => Promise<void>,
  getHRVData: (pageCmd?: PageCMD) => Promise<void>,
  getSpO2Data: (pageCmd?: PageCMD) => Promise<void>,
  getBodyTempData: (pageCmd?: PageCMD) => Promise<void>,
  getSleepDetails: (pageCmd?: PageCMD) => Promise<void>,
  getBPData: (pageCmd?: PageCMD) => Promise<void>,
  setBPCalibration: (systolic: number, diastolic: number) => Promise<void>,
  getBPCalibration: () => Promise<void>,

  enterDFUMode: () => Promise<void>
}


export function makeProtocol(
  ble: BluetoothlePlugin.Bluetoothle,
  device: Device,
  deviceFamily: Data.DeviceFamily = Data.DeviceFamily.JStyle1963YH
): JStyle1963YHProtocol {
  const access = makeDeviceAccess(ble, device);
  let state: Data.State = Data.initState();
  const events = new EventEmitter<ChangeEvent | DoneEvent>();
  const log = makeLogger('BLE.Protocol.JStyle1963YH', 'BLUETOOTH_LOG');
  const warn =
    makeLogger('BLE.Protocol.JStyle1963YH', 'BLUETOOTH_LOG', 'warn');
  let subscribed = false;

  // Stores timeout resolution functions used by `detectNextPage`, see below
  let nextPageTimeout: { [cmd: number]: (() => void) | undefined } = {};

  return {
    // Listeners
    onChange,
    onChangeHRVs,
    onChangeHRs,
    onChangeSpO2s,
    onChangeBodyTemps,
    onChangeDailySummaries,
    onChangeSleepDetails,
    onChangeBPs,

    getState,

    // Commands
    init,
    close,
    supportsAutoMonitoring,
    supportsBPCalibration,
    getVersion,
    getTime,
    setTime,
    getDeviceSettings,
    setDeviceSettings,
    getAutoMonitoring,
    setAutoHRMonitoring,
    setAutoBPMonitoring,
    setAutoSpo2Monitoring,
    setAutoBodyTempMonitoring,
    getBattery,
    getDailySummaries,
    getDailyDetails,
    getHRData,
    getHRVData,
    getSpO2Data,
    getBodyTempData,
    getSleepDetails,
    getBPData,
    setBPCalibration,
    getBPCalibration,
    enterDFUMode
  };

  function onChange(fn: (state: Data.State) => void) {
    events.on(ChangeEvent.STATE, fn);
  }

  function onChangeHRVs(fn: (hrvs: Data.HRV[]) => void) {
    events.on(ChangeEvent.HRVS, fn);
  }

  function onChangeHRs(fn: (hrs: Data.HR[]) => void) {
    events.on(ChangeEvent.HRS, fn);
  }

  function onChangeSpO2s(fn: (spo2s: Data.SpO2[]) => void) {
    events.on(ChangeEvent.SPO2S, fn);
  }

  function onChangeBodyTemps(fn: (bodyTemps: Data.BodyTemp[]) => void) {
    events.on(ChangeEvent.BODY_TEMPS, fn);
  }

  function onChangeDailySummaries(f: (s: Data.DailyActivitySummary[]) => void) {
    events.on(ChangeEvent.DAILY_SUMMARIES, f);
  }

  function onChangeSleepDetails(fn: (details: Data.SleepDetail[]) => void) {
    events.on(ChangeEvent.SLEEP_DETAILS, fn);
  }

  function onChangeBPs(fn: (bps: Data.BP[]) => void) {
    events.on(ChangeEvent.BPS, fn);
  }

  function getState(): Data.State {
    return state;
  }

  async function init() {
    log('Connecting...');
    const isConnected = await access.isConnected();
    if (!isConnected) {
      const wasConnected = await access.wasConnected();
      const onDisconnect = () => {
        events.emit(DoneEvent.DISCONNECT);
      }
      if (wasConnected) {
        await access.reconnect(onDisconnect);
      } else {
        await access.connect(onDisconnect);
      }
    }
    log('Discovering...');
    const isDiscovered = await access.isDiscovered();
    if (!isDiscovered) {
      await access.discover();
    }
    log('Subscribing...');
    if (subscribed) { return; }

    await access.subscribe(
      SERVICE, READ_CHAR, handlePacketFromSubscription
    ).catch(async (e) => {
      // Ignore this error
      if (e === 'Already subscribed') { return; }
      throw e;
    });

    await getVersion();

    subscribed = true;
  }

  /**
   * Weird stuff going on in this function since BLE across the platforms and
   * devices behaves strangely, sometimes never responding to disconnects, other
   * times failing, othertimes etc etc, but we just want to make sure that after
   * trying all that we close the connection
   */
  async function close() {
    try {
      await access.unsubscribe(SERVICE, READ_CHAR).catch(e => {
        if (e === 'Already unsubscribed') { return; }
        throw e;
      })
      subscribed = false;
      await Timeout.timeout_(
        5000,
        (resolve, reject) => access.disconnect().then(resolve).catch(reject),
        'Timed out.'
      ).catch(e => {
        if (e === 'Timed out.') {
          console.warn(
            'Timed out while disconnecting, assuming we are disconnected.'
          )
        } else {
          throw e;
        }
      });
    } finally {
      await access.close();
    }
  }

  async function supportsAutoMonitoring(
    dataType: Data.AutoMonitoringType
  ): Promise<boolean> {
    // after this minimum version, auto monitoring is supported for all data
    // types. before this version, only HR is supported
    const meetsMinimum =
      await minimumVersionByFamily({ [DeviceFamily.JStyle1963YH]: [1, 5, 9] });
    if (meetsMinimum) {
      return true;
    } else {
      return dataType === Data.AutoMonitoringType.HR;
    }
  }

  function supportsBPCalibration(): Promise<boolean> {
    return minimumVersionByFamily({
      [DeviceFamily.JStyle1963YH]: [1, 7, 5],
      [DeviceFamily.JStyle1790]: [1, 0, 0]
    });
  }

  async function getVersion(): Promise<void> {
    writePacket(commandPacket(CMD.GET_VERSION));
    await waitFor(ChangeEvent.VERSION);
  }

  async function getTime(): Promise<void> {
    writePacket(commandPacket(CMD.GET_TIME));
    await waitFor(ChangeEvent.TIME);
  }

  // Command Format: 0x01 AA BB CC DD EE FF 00 00 00 00 00 00 00 00 CRC
  // Description: AA = year, BB = month, CC = day, DD = hour, EE = minute, FF =
  // second, the format is BCD, for example, 12 year, AA = 0x12.
  async function setTime(time?: moment.Moment): Promise<void> {
    time = time || moment();
    writePacket(
      commandPacket(
        CMD.SET_TIME,
        Codings.bcdEncode(time.year() - 2000), // only send last 2 digits
        Codings.bcdEncode(time.month() + 1), // convert to 1-index
        Codings.bcdEncode(time.date()),
        Codings.bcdEncode(time.hour()),
        Codings.bcdEncode(time.minute()),
        Codings.bcdEncode(time.second())
      )
    );
    await waitFor(DoneEvent.SET_TIME);
  }

  async function getDeviceSettings(): Promise<void> {
    writePacket(commandPacket(CMD.GET_DEVICE_SETTINGS));
    await waitFor(ChangeEvent.DEVICE_SETTINGS);
  }

  async function setDeviceSettings(
    settings: Data.DeviceSettings
  ): Promise<void> {
    const params = Codings.DeviceSettings.encodeParams(settings);
    writePacket(commandPacket(CMD.SET_DEVICE_SETTINGS, ...Array.from(params)));
    await waitFor(DoneEvent.SET_DEVICE_SETTINGS)
  }

  async function setAutoHRMonitoring(
    opts: IntervalicMonitoringOpts
  ): Promise<void> {
    await setIntervalicMonitoring(Data.AutoMonitoringType.HR, opts);
  }

  async function setAutoBPMonitoring(
    opts: IntervalicMonitoringOpts
  ): Promise<void> {
    await setIntervalicMonitoring(Data.AutoMonitoringType.BP, opts);
  }

  async function setAutoSpo2Monitoring(
    opts: IntervalicMonitoringOpts
  ): Promise<void> {
    await setIntervalicMonitoring(Data.AutoMonitoringType.SPO2, opts);
  }

  async function setAutoBodyTempMonitoring(
    opts: IntervalicMonitoringOpts
  ): Promise<void> {
    await setIntervalicMonitoring(Data.AutoMonitoringType.BODY_TEMP, opts);
  }

  // Command format: 0x2A AA BB CC DD EE FF GG HH 00 00 00 00 00 00 CRC
  // Function: Set the HR monitoring period.
  // Description:
  // AA: HR monitoring mode, 0: Turn off, 1: HR monitoring mode in time period,
  //   2: HR monitoring in set time interval.
  // BB: Stands for the hour of the time start HR monitoring, (24-hour clock),
  //   BCD code format (such as 23:00, means BB = 0x23).
  // CC: Stands for the minute of the time start HR monitoring, (24-hour clock),
  //   BCD code format (such as 00:59, means CC = 0x59).
  // DD: Stands for the hour of the time end HR monitoring (24-hour clock), BCD
  //   code format (such as 23:00, means DD = 0x23).
  // EE: stands for the minute of the time end HR monitoring, BCD code format
  //   (such as 00:59, means EE = 0x59).
  // FF: Stands for week enable bit.
  // Bit0 = 0 Sunday disable, Bit0 = 1 Sunday enable
  // Bit1 = 0 Monday Disable, Bit1 = 1 Monday enable
  // Bit2 = 0 Tuesday Disable, Bit2 = 1 Tuesday enable
  // Bit3 = 0 Wednesday Disable, Bit3 = 1 Wednesday enable
  // Bit4 = 0 Thursday Disable, Bit4 = 1 Thursday enable
  // Bit5 = 0 Friday Disable, Bit5 = 1 Friday enable
  // Bit6 = 0 Saturday Disable, Bit6 = 1 Saturday enable
  // GG HH: When HR monitoring mode is 2, namely, when AA=2, it means how often
  //   to test once, the unit is minute, and each measurement time is one
  //   minute.
  //
  // II - select data type to monitor.  Only works on firmware 1.5.9 and above.
  // Below 1.5.9 only HR is monitored and this byte is ignored (maybe?)
  // =01 Heart rate automatic detection;
  // =02 Blood oxygen automatic detection;
  // =03 Body temperature automatic detection;
  // =04 Blood pressure automatic detection
  async function setIntervalicMonitoring(
    dataType: Data.AutoMonitoringType,
    opts=DEFAULT_INTERVALIC_MONITORING_OPTS
  ): Promise<void> {
    const dataTypeByte = await autoMonitoringDataTypeByte(dataType);
    if (dataTypeByte !== undefined) {
      writePacket(
        commandPacket(
          CMD.SET_AUTO_MONITORING,
          2,
          Codings.bcdEncode(withDefault(0, opts.startHour)),
          Codings.bcdEncode(withDefault(0, opts.startMinute)),
          Codings.bcdEncode(withDefault(23, opts.endHour)),
          Codings.bcdEncode(withDefault(59, opts.endMinute)),
          0b1111111,
          opts.minutes,
          0,
          dataTypeByte
        )
      );
      await waitFor(DoneEvent.SET_AUTO_MONITORING);
    } else {
      warnCmdUnavailable(CMD.SET_AUTO_MONITORING, `data type: ${dataType}`);
    }
  }

  async function getAutoMonitoring(): Promise<void> {
    for (const dataType in Data.AutoMonitoringType) {
      const dataTypeByte = await autoMonitoringDataTypeByte(
        dataType as Data.AutoMonitoringType
      );
      if (dataTypeByte !== undefined) {
        writePacket(
          commandPacket(CMD.GET_AUTO_MONITORING, dataTypeByte)
        );
        await waitFor(ChangeEvent.AUTO_MONITORING);
      } else {
        warnCmdUnavailable(CMD.GET_AUTO_MONITORING, `data type: ${dataType}`);
      }
    }
  }

  async function getBattery(): Promise<void> {
    writePacket(commandPacket(CMD.GET_BATTERY));
    await waitFor(ChangeEvent.BATTERY);
  }

  async function getDailySummaries(): Promise<void> {
    resetData(PageCMD.LATEST, 'dailySummaries');
    writePacket(commandPacket(CMD.GET_DAILY_SUMMARIES));
    await waitFor(ChangeEvent.DAILY_SUMMARIES);
  }

  async function getDailyDetails(pageCmd=PageCMD.LATEST): Promise<void> {
    resetData(pageCmd, 'dailyDetails');
    writePacket(commandPacket(CMD.GET_DAILY_DETAILS, pageCmd));
    await waitFor(ChangeEvent.DAILY_DETAILS);
  }

  async function getHRData(pageCmd=PageCMD.LATEST): Promise<void> {
    resetData(pageCmd, 'hrs');
    writePacket(commandPacket(CMD.GET_HR_DATA, pageCmd));
    await waitFor(ChangeEvent.HRS);
  }

  async function getHRVData(pageCmd=PageCMD.LATEST): Promise<void> {
    resetData(pageCmd, 'hrvs');
    writePacket(commandPacket(CMD.GET_HRV_DATA, pageCmd));
    await waitFor(ChangeEvent.HRVS);
  }

  async function getSpO2Data(pageCmd=PageCMD.LATEST): Promise<void> {
    resetData(pageCmd, 'spo2s');
    writePacket(commandPacket(CMD.GET_SPO2, pageCmd));
    await waitFor(ChangeEvent.SPO2S);
  }

  async function getBodyTempData(pageCmd=PageCMD.LATEST): Promise<void> {
    resetData(pageCmd, 'bodyTemps');
    writePacket(commandPacket(CMD.GET_BODY_TEMPS, pageCmd));
    await waitFor(ChangeEvent.BODY_TEMPS);
  }

  async function getSleepDetails(pageCmd=PageCMD.LATEST): Promise<void> {
    resetData(pageCmd, 'sleepDetails');
    writePacket(commandPacket(CMD.GET_SLEEP_DETAILS, pageCmd));
    await waitFor(ChangeEvent.SLEEP_DETAILS);
  }

  async function getBPData(pageCmd=PageCMD.LATEST): Promise<void> {
    const meetsMinimum = await minimumVersionByFamily({
      [DeviceFamily.JStyle1963YH]: [1, 5, 9]
    });
    if (meetsMinimum) {
      resetData(pageCmd, 'bps');
      writePacket(commandPacket(CMD.GET_BP_DATA, pageCmd));
      await waitFor(ChangeEvent.BPS);
    } else {
      warnCmdUnavailable(CMD.GET_BP_DATA);
    }
  }

  async function setBPCalibration(
    systolic: number, diastolic: number
  ): Promise<void> {
    const meetsMinimum = await minimumVersionByFamily({
      [DeviceFamily.JStyle1963YH]: [1, 7, 5],
      [DeviceFamily.JStyle1790]: [1, 0, 0]
    })
    if (meetsMinimum) {
      writePacket(commandPacket(CMD.SET_BP_CALIBRATION, systolic, diastolic));
      await waitFor(DoneEvent.SET_BP_CALIBRATION);
    } else {
      warnCmdUnavailable(CMD.SET_BP_CALIBRATION);
    }
  }

  async function getBPCalibration(): Promise<void> {
    setState(Data.clearData('bpCalibration', state));
    writePacket(commandPacket(CMD.GET_BP_CALIBRATION));
    await waitFor(ChangeEvent.BP_CALIBRATION);
  }

  async function enterDFUMode(): Promise<void> {
    try {
      writePacket(commandPacket(CMD.ENTER_DFU_MODE));
      await waitFor(DoneEvent.DISCONNECT);
    } catch (e) {
      warn('Error while entering DFU mode:', e);
      warn('Attempting to continue...');
    } finally {
      await close().catch();
    }
  }

  // private

  async function waitFor(event: Event): Promise<void> {
    return new Promise(resolve => events.once(event, resolve));
  }

  async function minimumVersionByFamily(
    minimums: { [family in DeviceFamily]?: (Version.Version | undefined) }
  ): Promise<boolean> {
    const v = await fetchCurrentVersion();
    const min = minimums[v.family]
    if (min) {
      return Version.compare(v.version, min) >= 0;
    } else {
      return false;
    }
  }

  async function warnCmdUnavailable(cmd: CMD, detail?: string) {
    const cmdName = CMD[cmd];
    const v = await fetchCurrentVersion();

    warn(
      `${cmdName} (CMD 0x${cmd.toString(16)}) is not available on ` +
        `${v.family} version ${Version.display(v.version)}` +
        (detail ? ` - ${detail}` : '')
    );
  }

  function setState(newState: Data.State): void {
    state = newState;
  }

  function resetData(pageCmd: PageCMD, key: keyof Data.State) {
    if (pageCmd === PageCMD.LATEST) {
      setState(Data.clearData(key, state));
    }
  }

  function emitChange(
    e: ChangeEvent, key: keyof Data.State, state: Data.State
  ) {
    events.emit(ChangeEvent.STATE, state);
    events.emit(e, state[key]);
  }

  function commandPacket(
    command: number, ...params: Array<number>
  ): Uint8Array {
    const packet = new Uint8Array(16);
    packet[0] = command;
    if (params) {
      params.forEach((value, i) => packet[i + 1] = value);
    }
    return CRC.add(packet);
  }

  function handlePacketFromSubscription(packet: Uint8Array) {
    const command: CMD = packet[0];
    const firmwareVersion = getState().version;

    logPacket(command, packet);
    dataReceived(command);

    switch(command) {
      case CMD.GET_VERSION:
        const version = Codings.GetVersion.decode(packet)
        setState({ ...state, version: { version, family: deviceFamily }});
        emitChange(ChangeEvent.VERSION, 'version', state);
        return;

      case CMD.SET_TIME:
        events.emit(DoneEvent.SET_TIME);
        return;

      case CMD.GET_TIME:
        const time = Codings.GetTime.decode(packet)
        setState({ ...state, time });
        emitChange(ChangeEvent.TIME, 'time', state);
        return;

      case CMD.GET_DEVICE_SETTINGS:
        const deviceSettings = Codings.DeviceSettings.decode(packet);
        setState({ ...state, deviceSettings });
        emitChange(ChangeEvent.DEVICE_SETTINGS, 'deviceSettings', state);
        return;

      case CMD.SET_DEVICE_SETTINGS:
        events.emit(DoneEvent.SET_DEVICE_SETTINGS);
        return;

      case CMD.SET_AUTO_MONITORING:
        events.emit(DoneEvent.SET_AUTO_MONITORING);
        return;

      case CMD.GET_AUTO_MONITORING:
        const autoMonitoring = Codings.GetAutoMonitoring.decode(packet);
        setState({
          ...state,
          autoMonitoring: {
            ...state.autoMonitoring,
            [autoMonitoring.dataType]: autoMonitoring
          }
        });
        emitChange(ChangeEvent.AUTO_MONITORING, 'autoMonitoring', state);
        return;

      case CMD.GET_BATTERY:
        const battery = Codings.GetBattery.decode(packet);
        setState({ ...state, battery });
        emitChange(ChangeEvent.BATTERY, 'battery', state);
        return;

      case CMD.GET_DAILY_SUMMARIES:
        const summaries = Codings.GetDailySummaries.decode(packet)
        setState(Data.addDailyActivitySummaries(summaries, state));
        if (Codings.isFinalPacket(command, packet)) {
          emitChange(ChangeEvent.DAILY_SUMMARIES, 'dailySummaries', state);
        }
        return;

      case CMD.GET_DAILY_DETAILS:
        const dailyDetails = Codings.GetDailyDetails.decode(packet)
        setState(Data.addDailyActivityDetails(dailyDetails, state));
        if (Codings.isFinalPacket(command, packet)) {
          emitChange(ChangeEvent.DAILY_DETAILS, 'dailyDetails', state);
        }
        return;

      case CMD.GET_HR_DATA:
        handlePagedData<Data.HR>(
          packet,
          Codings.GetHRData.decode,
          Data.addHRs,
          CMD.GET_HR_DATA,
          ChangeEvent.HRS,
          'hrs'
        );
        return;

      case CMD.GET_HRV_DATA:
        if (!firmwareVersion) return;
        handlePagedData<Data.HRV>(
          packet,
          Codings.GetHRVData.decode(firmwareVersion),
          Data.addHRVs,
          CMD.GET_HRV_DATA,
          ChangeEvent.HRVS,
          'hrvs'
        );
        return;

      case CMD.GET_SPO2:
        if (!firmwareVersion) return;
        handlePagedData<Data.SpO2>(
          packet,
          Codings.GetSpO2Data.decode(firmwareVersion),
          Data.addSpO2s,
          CMD.GET_SPO2,
          ChangeEvent.SPO2S,
          'spo2s'
        );
        return;

      case CMD.GET_BODY_TEMPS:
        if (!firmwareVersion) return;
        handlePagedData<Data.BodyTemp>(
          packet,
          Codings.GetBodyTempData.decode(firmwareVersion),
          Data.addBodyTemps,
          CMD.GET_BODY_TEMPS,
          ChangeEvent.BODY_TEMPS,
          'bodyTemps'
        );
        return;

      case CMD.GET_SLEEP_DETAILS:
        const sleepDetails = Codings.GetSleepDetails.decode(packet)
        setState(Data.addSleepDetails(sleepDetails, state));
        if (Codings.isFinalPacket(command, packet)) {
          emitChange(ChangeEvent.SLEEP_DETAILS, 'sleepDetails', state);
        }
        return;

      case CMD.GET_BP_DATA:
        handlePagedData<Data.BP>(
          packet,
          Codings.GetBPData.decode,
          Data.addBPs,
          CMD.GET_BP_DATA,
          ChangeEvent.BPS,
          'bps'
        );
        return;

      case CMD.SET_BP_CALIBRATION:
        events.emit(DoneEvent.SET_BP_CALIBRATION);
        return;

      case CMD.GET_BP_CALIBRATION:
        const bpCalibration = Codings.GetBPCalibration.decode(packet);
        setState({ ...state, bpCalibration });
        emitChange(ChangeEvent.BP_CALIBRATION, 'bpCalibration', state);
        return;

      case CMD.ENTER_DFU_MODE:
        events.emit(DoneEvent.ENTER_DFU_MODE);
        return;

      default:
        return unhandledCommand(command);
    }
  }

  function handlePagedData<T>(
    packet: Uint8Array,
    decode: (packet: Uint8Array) => T[],
    addToState: (data: T[], state: Data.State) => Data.State,
    cmd: CMD,
    event: ChangeEvent,
    stateKey: keyof Data.State
  ) {
    const data = decode(packet)
    setState(addToState(data, getState()));
    if (Codings.isFinalPacket(cmd, packet)) {
      emitChange(event, stateKey, state);
    } else {
      detectNextPage(cmd);
    }
  }

  /**
   * This complicated function is used to detect when we have stopped receiving
   * data in response to a certain command, but never recieved the "final bytes"
   * that indicate all data has been sent.  In this situation, we need to
   * request the next page of data if we want everything.
   * A timeout is used to achieve this.  If we haven't received any new data in
   * response to a request in the <timeout> number of seconds, then we assume it
   * is because no more data is coming, and at that point we isuse the request
   * for the next page.  The `dataReceived` function is invoked everytime data
   * is received so we can cancel the previous timeout before starting a new one
   */
  function detectNextPage(cmd: CMD) {
    Timeout.timeout_(
      1500,
      done => nextPageTimeout[cmd] = done
    ).catch(
      () => {
        log(`Timed out waiting for data from command ${cmd}. Requesting "next" page of data.`);
        nextPageTimeout[cmd] = undefined;
        writePacket(commandPacket(cmd, PageCMD.NEXT));
      }
    );
  }

  function dataReceived(cmd: CMD) {
    const fn = nextPageTimeout[cmd];
    if (fn) {
      fn();
      nextPageTimeout[cmd] = undefined;
    }
  }

  async function autoMonitoringDataTypeByte(
    dataType: Data.AutoMonitoringType
  ): Promise<number | undefined> {
    const meetsMinimum =
      await minimumVersionByFamily({ [DeviceFamily.JStyle1963YH]: [1, 5, 9] });
    if (meetsMinimum) {
      return Data.autoMonitoringTypeToByte(dataType);
    } else if (dataType === Data.AutoMonitoringType.HR) {
      return 0;
    }
  }

  async function writePacket(packet: Uint8Array): Promise<void> {
    await access.write(SERVICE, WRITE_CHAR, packet);
  }

  /**
   * pulls current version from state, or queries the device if we don't have
   * it loaded yet
   **/
  async function fetchCurrentVersion(): Promise<Data.DeviceVersion> {
    const current = getState().version;
    if (current) {
      return current;
    } else {
      await getVersion();
      // Use the not-null assertion since we know that the version has been
      // loaded now (maybe kinda sketch but at least its isolated)
      return getState().version!;
    }
  }

  function logPacket(cmd: CMD, packet: Uint8Array) {
    const cmdName = CMD[cmd];
    log(`Decoding ${cmdName} packet:`, packet);
  }

  function unhandledCommand(x: never) {
    log(`Received unrecognized command: ${x}`);
  }

  function withDefault<T>(def: T, value: T | undefined): T {
    return value === undefined ? def : value;
  }
}
