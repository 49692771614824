import * as R from 'ramda';
import { Specs, SpecType, ExpandedSpecType } from './Data';
import { SpecsJSON, SpecTypeJSON, ExpandedSpecTypeJSON } from 'Api/JSON/User';

const SPEC_TYPE_MAP: { [K in SpecType]: SpecTypeJSON } = {
  heightFeet: 'height_feet',
  heightInches: 'height_inches',
  restingHeartRate: 'resting_heart_rate',
  systolicBp: 'systolic_bp',
  diastolicBp: 'diastolic_bp',
  bodyFat: 'body_fat',
  weight: 'weight',
  waist: 'waist',
  bmi: 'bmi',
  glucose: 'glucose',
  a1c: 'a1c',
  ldlCholesterol: 'ldl_cholesterol',
  hdlCholesterol: 'hdl_cholesterol',
  bodyTemp: 'body_temp',
  spo2: 'spo2',
  hrv: 'hrv',
  respiratoryRate: 'respiratory_rate',
  stressScore: 'stress_score',
  fev1: 'fev1',
  pef: 'pef'
};

const EXPANDED_SPEC_TYPE_MAP: {
  [K in ExpandedSpecType]: ExpandedSpecTypeJSON
} = {
    ...SPEC_TYPE_MAP,
  height: 'height',
  bloodPressure: 'blood_pressure',
  gender: 'gender'
};

export function specTypeFromJSON(json: SpecTypeJSON): SpecType {
  return R.invertObj(SPEC_TYPE_MAP)[json] as SpecType;
}

export function expandedSpecTypeFromJSON(
  json: ExpandedSpecTypeJSON
): ExpandedSpecType {
  return R.invertObj(EXPANDED_SPEC_TYPE_MAP)[json] as ExpandedSpecType;
}

export function specTypeToJSON(specType: SpecType): SpecTypeJSON {
  return SPEC_TYPE_MAP[specType];
}

export function specsFromJSON(json: SpecsJSON): Specs {
  return R.reduce(
    (acc, specTypeJSON: SpecTypeJSON) => R.assoc(
      specTypeFromJSON(specTypeJSON),
      json[specTypeJSON],
      acc
    ), {}, R.keys(json)) as Specs;
}
