import * as R from 'ramda';
import * as moment from 'moment-timezone'
import { StoreDispatch } from 'Store';
import { makeLogger } from 'Shared/Log';
import { ActionCreators } from 'Edge/ActionCreator';
import * as Edge from 'Edge/Data';
import * as BLE from 'BLE';
import * as JStyle from 'BLE/Protocols/JStyle1963YH/Data';
import {
  JStyle1963YHProtocol, makeProtocol
} from 'BLE/Protocols/JStyle1963YH/Protocol';

import { globalRemoteLogger } from 'Shared/Log';

enum CollectionType {
  HRV,
  SpO2,
  BodyTemp,
  DailySummaries,
  Sleep,
  ContinuousHR,
  IntervalicBP
}

const SUPPORTED_COLLECTION_TYPES:
{ [f in JStyle.DeviceFamily]: CollectionType[] } = {
  [JStyle.DeviceFamily.JStyle1963YH]: [
    CollectionType.HRV,
    CollectionType.SpO2,
    CollectionType.BodyTemp,
    CollectionType.DailySummaries,
    CollectionType.Sleep,
    CollectionType.ContinuousHR,
    CollectionType.IntervalicBP
  ],
  [JStyle.DeviceFamily.JStyle1790]: [
    CollectionType.HRV,
    CollectionType.DailySummaries,
    CollectionType.ContinuousHR,
    CollectionType.IntervalicBP
  ]
};

export function makeCollector(
  dispatch: StoreDispatch,
  bluetooth: BLE.Service,
  deviceType: Edge.DeviceType,
  device: Edge.UserDevice,
  deviceFamily: JStyle.DeviceFamily = JStyle.DeviceFamily.JStyle1963YH
): Edge.Collector {
  let protocol: JStyle1963YHProtocol;
  let dataBuffer: Edge.DeviceData = {};
  let remoteLogger = globalRemoteLogger().withTag('JStyle Collector');
  const log = makeLogger('Edge.Collector.JStyle1963YH', 'EDGE_LOG');

  return { sync };

  async function sync() {
    remoteLogger.log('Beginning sync.');
    const protocol = await setup(deviceFamily);

    remoteLogger.log('Set time...');
    await protocol.setTime();
    await setupDeviceSettings();
    await setupAutoMonitoring();
    if (isSupported(CollectionType.HRV)) {
      remoteLogger.log('Get HRV...');
      await protocol.getHRVData();
    }
    if (isSupported(CollectionType.SpO2)) {
      remoteLogger.log('Get SpO2...');
      await protocol.getSpO2Data();
    }
    if (isSupported(CollectionType.BodyTemp)) {
      remoteLogger.log('Get Body temp...');
      await protocol.getBodyTempData();
    }
    if (isSupported(CollectionType.DailySummaries)) {
      remoteLogger.log('Get daily summaries...');
      await protocol.getDailySummaries();
    }
    if (isSupported(CollectionType.Sleep)) {
      remoteLogger.log('Get Sleep details...');
      await protocol.getSleepDetails();
    }
    if (isSupported(CollectionType.ContinuousHR)) {
      remoteLogger.log('Get continuous HR...');
      await protocol.getHRData();
    }
    if (isSupported(CollectionType.IntervalicBP)) {
      remoteLogger.log('Get BP data...');
      await protocol.getBPData();
    }

    remoteLogger.log('Flush queued data...');
    await flushQueuedData();
    await protocol.close();
    remoteLogger.log('Done!');
  }

  async function setup(
    deviceFamily: JStyle.DeviceFamily
  ): Promise<JStyle1963YHProtocol> {
    if (protocol === undefined) {
      const ble = await bluetooth.withBluetooth();
      protocol =
        makeProtocol(ble, Edge.userDeviceToBLEDevice(device), deviceFamily);
      await protocol.init();

      protocol.onChangeHRVs(
        hrvs => {
          log('HRVS received:', hrvs)
          queueData({
            restingHeartRates: hrvsToHRData(hrvs),
            bloodPressures: hrvsToBPData(hrvs),
            hrvs: hrvsToHRVData(hrvs),
            stressScores: hrvsToStressScoreData(hrvs)
          });
        }
      );
      protocol.onChangeSpO2s(
        spo2s => {
          log('SpO2s received:', spo2s);
          queueData({ spo2s: spo2sToSpO2Data(spo2s) })
        }
      );
      protocol.onChangeBodyTemps(
        bodyTemps => {
          log('Body temps received:', bodyTemps);
          queueData({ bodyTemps: bodyTempsToBodyTempData(bodyTemps) })
        }
      );
      protocol.onChangeDailySummaries(
        summaries => {
          log('Daily summaries received:', summaries);
          queueData({
            steps: dailySummariesToStepsData(summaries),
            activeMinutes: dailySummariesToActiveMinutesData(summaries)
          });
        }
      );
      protocol.onChangeSleepDetails(
        details => {
          const sleeps = sleepDetailsToSleepData(details);
          log('Sleep details received:', details);
          log('Sleep sessions analyzed:', sleeps);
          queueData({ sleeps });
        }
      );
      protocol.onChangeHRs(
        hrs => {
          log('HRs received:', hrs)
          queueData({ continuousHeartRates: hrsToHRData(hrs) });
        }
      );
      protocol.onChangeBPs(
        bps => {
          log('BPs received:', bps);
          queueData({ bloodPressures: bpsToBPData(bps) });
        }
      )

      log('Protocol initialized and change handlers setup:', protocol);
    }

    return protocol;
  }

  function queueData(newData: Edge.DeviceData) {
    dataBuffer = Edge.mergeDeviceData(dataBuffer, newData);
  }

  async function flushQueuedData() {
    await dispatch(
      ActionCreators.receiveDeviceData(
        deviceType.providerId,
        dataBuffer,
        device
      )
    );
    dataBuffer = {};
  }

  async function setupDeviceSettings() {
    await protocol.getDeviceSettings();
    const settings = protocol.getState().deviceSettings;
    if (settings) {
      await protocol.setDeviceSettings({ ...settings, temperatureUnit: 'F' });
    }
  }

  async function setupAutoMonitoring() {
    if (await protocol.supportsAutoMonitoring(JStyle.AutoMonitoringType.HR)) {
      remoteLogger.log('Enable intervalic HR...');
      await protocol.setAutoHRMonitoring({ minutes: 15 });
    }
    if (await protocol.supportsAutoMonitoring(JStyle.AutoMonitoringType.BP)) {
      remoteLogger.log('Enable intervalic BP...');
      await protocol.setAutoBPMonitoring({
        minutes: 60, startHour: 0, startMinute: 5
      });
    }
    if (await protocol.supportsAutoMonitoring(JStyle.AutoMonitoringType.SPO2)) {
      remoteLogger.log('Enable intervalic SpO2...');
      await protocol.setAutoSpo2Monitoring({ minutes: 30 });
    }
    if (
      await protocol.supportsAutoMonitoring(JStyle.AutoMonitoringType.BODY_TEMP)
    ) {
      remoteLogger.log('Enable intervalic body temp...');
      await protocol.setAutoBodyTempMonitoring({ minutes: 30 });
    }
  }

  function isSupported(ct: CollectionType): boolean {
    return R.contains(ct, SUPPORTED_COLLECTION_TYPES[deviceFamily]);
  }
}

function hrvsToHRData(hrvs: JStyle.HRV[]): Edge.HRDatum[] {
  return hrvs.map(hrv => ({ time: hrv.time, heartRate: hrv.heartRate }));
}

function hrvsToBPData(hrvs: JStyle.HRV[]): Edge.BPDatum[] {
  return hrvs.map(hrv => ({
    time: hrv.time, systolic: hrv.systolicBP, diastolic: hrv.diastolicBP
  }));
}

function bpsToBPData(bps: JStyle.BP[]): Edge.BPDatum[] {
  return bps.map(bp => ({
    time: bp.time, systolic: bp.systolicBP, diastolic: bp.diastolicBP
  }));
}

function hrsToHRData(hrs: JStyle.HR[]): Edge.HRDatum[] {
  return hrs.map(hr => ({ time: hr.time, heartRate: hr.heartRate }));
}

function spo2sToSpO2Data(spo2s: JStyle.SpO2[]): Edge.SpO2Datum[] {
  return spo2s.map(spo2 => ({ time: spo2.time, spo2: spo2.spo2 }));
}

function bodyTempsToBodyTempData(
  bodyTemps: JStyle.BodyTemp[]
): Edge.BodyTempDatum[] {
  return bodyTemps.map(t => ({ time: t.time, bodyTemp: t.bodyTemp }));
}

function dailySummariesToStepsData(
  summaries: JStyle.DailyActivitySummary[]
): Edge.StepsDatum[] {
  return summaries.map(s => ({ time: s.date, stepCount: s.stepCount }));
}

function dailySummariesToActiveMinutesData(
  summaries: JStyle.DailyActivitySummary[]
): Edge.ActiveMinutesDatum[] {
  return summaries.map(s => ({ time: s.date, activeMinutes: s.activeMinutes }));
}

function hrvsToHRVData(hrvs: JStyle.HRV[]): Edge.HRVDatum[] {
  return hrvs.map(hrv => ({ time: hrv.time, hrv: hrv.hrv }));
}

function hrvsToStressScoreData(
  hrvs: JStyle.HRV[]
): Edge.StressScoreDatum[] {
  return hrvs.map(hrv => ({ time: hrv.time, stressScore: hrv.stressLevel }));
}


function sleepDetailsToSleepData(
  details: JStyle.SleepDetail[]
): Edge.SleepDatum[] {
  const data = groupSleepDetailsByNight(details).map(
    (night: JStyle.SleepDetail[]) => {
      const time = sleepEndTime(night[night.length - 1]);
      const asleepDuration = R.sum(night.map(d => d.length * 5));
      return { time, asleepDuration };
    }
  );

  return R.reject(sleepEndedRecently, data);
}

function groupSleepDetailsByNight(
  details: JStyle.SleepDetail[]
): JStyle.SleepDetail[][] {
  const sorted = R.sortBy(d => d.time.unix(), details)
  let result: JStyle.SleepDetail[][] = [];
  let night: JStyle.SleepDetail[] = [];
  sorted.forEach(detail => {
    const previous = R.last(night);

    if (previous === undefined || !sleepsAreConsecutive(previous, detail)) {
      night = [detail];
      result.push(night);
    } else {
      night.push(detail);
    }
  });

  return result;
}

function sleepEndTime(detail: JStyle.SleepDetail): moment.Moment {
  return detail.time.clone().add(
    detail.length * 5,
    'minutes'
  );
}

const SLEEP_SESSION_THRESHOLD_SECONDS = 2 * 60 * 60;

function sleepsAreConsecutive(
  first: JStyle.SleepDetail, last: JStyle.SleepDetail
): boolean {
  const diff = last.time.diff(sleepEndTime(first), 'seconds');
  return diff <= SLEEP_SESSION_THRESHOLD_SECONDS;
}

function sleepEndedRecently(datum: Edge.SleepDatum): boolean {
  const secondsSinceEnd = moment().diff(datum.time, 'seconds');
  return secondsSinceEnd <= SLEEP_SESSION_THRESHOLD_SECONDS;
}
