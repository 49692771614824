import * as R from 'ramda';
import * as React from 'react';
import { defaultConnect } from 'Shared/ReduxComponent';
import { RouteComponentProps } from 'react-router-dom'
import { State, StoreDispatch } from 'Store';
import { SEL, State as InboxState, ThreadInfo, Message } from 'Inbox/Data';

import * as Urls from 'Shared/Urls';
import Spinner from 'Shared/UI/Spinner';
import { profPicUrl } from 'Shared/ProfPic';
import { HUD } from 'HUD';
import { UI as NavUI } from 'Nav';
import featureGate from 'App/UI/FeatureGate';
import { Comment } from 'Comments/Data';
import * as Cordova from 'Cordova'

import { ActionCreators } from 'Inbox/ActionCreator';
import CommentThread from 'Comments/UI/CommentThread';
import CommentForm from 'Comments/UI/CommentForm';

type Props = State & RouteComponentProps<URLParams> & {
  dispatch: StoreDispatch
}

interface URLParams {
  threadId: string
}

interface UIState {
  submitting: boolean
}

class ShowThread extends React.Component<Props, UIState> {

  constructor(props: Props) {
    super(props);
    this.state = { submitting: false };
    this.submitMessage = this.submitMessage.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(
      ActionCreators.loadThread(this.props.match.params.threadId)
    );
  }

  inboxState(): InboxState {
    return this.props.inbox;
  }

  threadId(): string {
    return this.props.match.params.threadId;
  }

  threadInfo(): ThreadInfo | undefined {
    return SEL.getThreadInfo(this.threadId(), this.inboxState());
  }

  threadMessages(): Message[] | undefined {
    return SEL.getThreadMessages(this.threadId(), this.inboxState());
  }

  submitMessage(msg: string, onSuccess?: () => void) {
    this.props.dispatch(
      ActionCreators.sendMessage(this.threadId(), msg, {
        onSuccess: () => {
          this.setState({ submitting: false });
          if (onSuccess) { onSuccess(); }
        }
      })
    );
    this.setState({ submitting: true });
  }

  render() {
    const threadInfo = this.threadInfo();
    const messages = this.threadMessages();

    const content =
      threadInfo === undefined || messages === undefined ?
      <Spinner wrap="center"/> :
      this.renderThread(threadInfo, messages);

    return (
      <NavUI.NavBarLayout title="Messages" initBreadcrumb={Urls.inboxUrl()}>
        {content}
      </NavUI.NavBarLayout>
    );
  }

  renderThread(threadInfo: ThreadInfo, messages: Message[]) {
    return (
      <div className="inbox-container">
        <div>
          {threadInfo.participants.map(p => p.name).join(', ')}
        </div>
        <CommentThread comments={messagesToComments(threadInfo, messages)} />

        {threadInfo.closed && (
           <p className="inbox__thread-closed">
             Closed to new messages
           </p>
         )
        }

        <CommentForm
          placeholder=""
          buttonLabel="send"
          submit={this.submitMessage}
          disabled={threadInfo.closed} />
        {this.state.submitting && <HUD state="loading" />}

        {Cordova.platform() === 'ios' && this.renderIosCordovaKeyboardHackDiv()}
      </div>
    );
  }

  /**
   * iOS 12 has bug where keyboard covers up inputs low on the page. So we put a
   * tall DIV in there to push it higher on the page!
   * https://github.com/apache/cordova-ios/issues/472
   */
  renderIosCordovaKeyboardHackDiv() {
    return (
      <div style={{height: '300px'}}></div>
    );
  }
}


export default featureGate(
  defaultConnect(ShowThread),
  ['inbox']
);

function messagesToComments(
  threadInfo: ThreadInfo, messages: Message[]
): Comment[] {
  return messages.map(m => messageToComment(threadInfo, m));
}

function messageToComment(threadInfo: ThreadInfo, message: Message): Comment {
  const user = R.find(u => u.id === message.userId, threadInfo.participants);
  return {
    system: false,
    postedBy: user && user.name || '',
    postedAt: message.postedAt,
    picUrl: profPicUrl(message.userId),
    text: message.text
  }
}
