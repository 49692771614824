import * as R from 'ramda';
import * as Lens from 'Shared/Lens';
import { Errors } from 'Shared/Data/Errors';
import {
  Loadable, loadable, loadableMap, isLoaded
} from 'misc/Data/Loadable';
import * as UUID from 'Shared/Data/UUID';
import { UserId, MinimalUser } from '1bios/User/Data';
import { Specs, SpecType, ExpandedSpecType, Gender } from 'Specs';
import { ProviderKey as EdgeProviderKey } from 'Edge';

const DEFAULT_PROGRAM_LOGO_URL: string =
  require('images/logo-with-text.svg').default;

/*
  ------------------------------------------------------------
  NS
  ------------------------------------------------------------
*/

export type NSType = 'USER';
export const NS: NSType = 'USER';

/*
  ------------------------------------------------------------
  Shared data re-exports
  ------------------------------------------------------------
*/

export { UserId, MinimalUser };

/*
  ------------------------------------------------------------
  Current User
  ------------------------------------------------------------
*/

export type HealthScore = number | undefined;

export enum ScreeningStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export enum InboxStatus {
  ALL_READ = 'ALL_READ',
  NOT_ALL_READ = 'NOT_ALL_READ'
}

export interface CurrentUserPayload {
  profile: Profile,
  program: Program,
  screeningStatus: ScreeningStatus,
  carePros: MinimalUser[],
  nativeAppSlug: string
}

export interface CurrentUser {
  loader: undefined | Promise<CurrentUserPayload>,
  profile: Profile | undefined,
  specs: Specs | undefined,
  program: Program | undefined
  carePros: MinimalUser[] | undefined,
  crew: CrewMember[] | undefined,
  screeningStatus: Loadable<ScreeningStatus>,
  crewInvites: Loadable<MinimalUser[]>,
  nativeAppSlug: Loadable<string>
}

export interface Profile {
  id: UserId,
  name: string,
  email: string,
  mobilePhone: string,
  gender: Gender,
  tzId: string,
  healthScore: HealthScore,
}

export interface CrewMember {
  user: MinimalUser,
  healthScore: HealthScore | undefined
}

export type UserType = Profile | MinimalUser | CrewMember;

export interface Program {
  id: string,
  name: string,
  slug: string,
  logoUrl: string | undefined,
  backgroundImageUrl: string | undefined,
  exposedSpecs: ExpandedSpecType[],
  edgeProviders: EdgeProviderKey[],
  features: AppFeatures,
  postPageLabel: string,
  whiteLabeled: boolean,
  htmlTitle: string
}

export interface AppFeatures {
  engagement: boolean,
  appts: boolean,
  crew: boolean,
  inbox: boolean,
  onboard: boolean,
  healthScore: boolean,
  displayBanner: boolean,
  allowExitHRA: boolean,
  carePlans: boolean,
  goals: boolean,
  screeningReports: boolean,
  challenges: boolean,
  userAppTrends: boolean,
  displayDailyCoach: boolean
}

export type AppFeatureKey = keyof AppFeatures;

/*
  ------------------------------------------------------------
  functions
  ------------------------------------------------------------
*/

export function initialCurrentUser(): CurrentUser {
  return {
    loader: undefined,
    profile: undefined,
    specs: undefined,
    program: undefined,
    carePros: undefined,
    crew: undefined,
    screeningStatus: loadable(),
    crewInvites: loadable(),
    nativeAppSlug: loadable()
  };
}


export function newProfile(): Profile {
  return {
    id: '',
    name: '',
    email: '',
    mobilePhone: '',
    gender: 'male',
    tzId: 'America/Los_Angeles',
    healthScore: undefined
  };
}

export function isCrewMember(user: UserType): user is CrewMember {
  const cm = user as CrewMember;
  return cm.user !== undefined;
}

export function isProfile(user: UserType): user is Profile {
  const p = user as Profile;
  return p.tzId !== undefined;
}

export function isMinimalUser(user: UserType): user is MinimalUser {
  return !isCrewMember(user) && !isProfile(user);
}

export function userName(user: UserType): string {
  if (isCrewMember(user)) {
    return user.user.name || '';
  } else {
    return user.name || '';
  }
}

export function userId(user: UserType): UserId {
  if (isCrewMember(user)) {
    return user.user.id;
  } else {
    return user.id;
  }
}

export function featuresEnabled(
  appFeatures: AppFeatures, required: AppFeatureKey[]
): boolean {
  return R.all(key => appFeatures[key], required);
}

export function loadableFeaturesEnabled(
  loadableFeatures: Loadable<AppFeatures>, requiredKeys: AppFeatureKey[]
): boolean {
  return isLoaded(loadableFeatures) &&
    featuresEnabled(loadableFeatures.value, requiredKeys);
}

export function withLoadableFeatures<R>(
  loadableFeatures: Loadable<AppFeatures>,
  requiredFeatureKeys: AppFeatureKey[],
  isSupported: R,
  notSupported?: R
): R | undefined {
  if (loadableFeaturesEnabled(loadableFeatures, requiredFeatureKeys)) {
    return isSupported;
  } else {
    return notSupported;
  }
}

// Validation

export function profileErrors(profile: Profile): Errors<Profile> {
  let errors: Errors<Profile> = {};
  if (profile.name === '') {
    errors.name = 'Name is required';
  }

  if (profile.email === '') {
    errors.email = 'Email is required';
  }

  if (profile.mobilePhone !== '' && !validPhone(profile.mobilePhone)) {
    errors.mobilePhone = 'Must be a 10-digit phone number';
  }

  return errors;
}

function validPhone(phone: string): boolean {
  return phoneNumsOnly(phone).length === 10;
}

function phoneNumsOnly(phone: string): string {
  return phone.toString().replace(/[^\d]/g, '');
}

export function formatPhone(val: string): string {
  const nums = phoneNumsOnly(val);
  if (nums.length !== 10) {
    return val;
  } else {
    return `(${nums.slice(0, 3)}) ${nums.slice(3, 6)}-${nums.slice(6, 10)}`;
  }
}
/*
  ------------------------------------------------------------
  Lenses
  ------------------------------------------------------------
*/

export interface Lenses {
  profile: R.Lens,
  profileField(fieldName: keyof ProfileLenses): R.Lens,
  specs: R.Lens,
  spec(s: SpecType): R.Lens,
  privacySettings: R.Lens,
  program: R.Lens
}

interface ProfileLenses {
  id: R.Lens,
  name: R.Lens,
  email: R.Lens,
  mobilePhone: R.Lens,
  gender: R.Lens,
  tzId: R.Lens
}

export type ProfileLensKey = keyof ProfileLenses;

const profileLenses: ProfileLenses = {
  id: R.lensProp('id'),
  name: R.lensProp('name'),
  email: R.lensProp('email'),
  mobilePhone: R.lensProp('mobilePhone'),
  gender: R.lensProp('gender'),
  tzId: R.lensProp('tzId')
};

export function makeLenses(root?: string): Lenses {
  const specs = Lens.addRoot(root, ['specs']);
  return {
    profile: Lens.addRoot(root, ['profile']),

    profileField(fieldName: keyof ProfileLenses): R.Lens {
      return Lens.addRoot(root, profileLenses[fieldName]);
    },

    specs,

    spec(s: SpecType) {
      return R.compose(specs, R.lensProp(s)) as R.Lens;
    },

    privacySettings: Lens.addRoot(root, ['privacySettings']),

    program: Lens.addRoot(root, ['program'])
  };
};

export const LENSES = makeLenses();

export const SEL = {
  profile(user: CurrentUser): Profile | undefined {
    return user.profile;
  },

  specs(user: CurrentUser): Specs | undefined {
    return R.view(LENSES.specs, user) as Specs | undefined;
  },

  userId(user: CurrentUser): string | undefined {
    return user.profile ? UUID.withDashes(user.profile.id) : undefined;
  },

  userName(user: CurrentUser): string | undefined {
    if (user.profile) {
      return user.profile.name;
    }
  },

  userTzId(user: CurrentUser): string {
    if (user.profile) { return user.profile.tzId; }
    else { return 'America/Los_Angeles'; }
  },

  screeningStatus(user: CurrentUser): Loadable<ScreeningStatus> {
    return user.screeningStatus;
  },

  carePros(user: CurrentUser): Loadable<MinimalUser[]> {
    return user.carePros === undefined ? loadable() : loadable(user.carePros);
  },

  crewMembers(user: CurrentUser): Loadable<CrewMember[]> {
    return user.crew === undefined ? loadable() : loadable(user.crew);
  },

  crewUsers(user: CurrentUser): Loadable<MinimalUser[]> {
    return loadableMap(
      members => members.map(m => m.user),
      SEL.crewMembers(user)
    );
  },

  crewMember(
    userId: UserId, user: CurrentUser
  ): Loadable<CrewMember | undefined> {
    return loadableMap(
      members => R.find(
        m => m.user.id === userId,
        members
      ),
      SEL.crewMembers(user)
    );
  },

  crewInvites(user: CurrentUser): Loadable<MinimalUser[]> {
    return user.crewInvites;
  },

  programLogoUrl(user: CurrentUser): string | undefined {
    if (user.program) {
      return user.program.logoUrl || DEFAULT_PROGRAM_LOGO_URL;
    }
  },

  programBackgroundImageUrl(user: CurrentUser): string | undefined {
    return user.program && user.program.backgroundImageUrl;
  },

  programName(user: CurrentUser): string {
    return user.program && user.program.name || '1bios';
  },

  exposedSpecs(user: CurrentUser): Loadable<ExpandedSpecType[]> {
    const specs = user.program && user.program.exposedSpecs
    return specs ? loadable(specs) : loadable();
  },

  appFeatures(user: CurrentUser): Loadable<AppFeatures> {
    return user.program ? loadable(user.program.features) : loadable();
  }
};
