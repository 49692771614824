import * as R from 'ramda';
import { Hour } from 'Shared/Data/TimeOfDay';
import { DayOfWeek } from 'Shared/Data/DayOfWeek';

import { addRoot } from 'Shared/Lens';

/*
  ------------------------------------------------------------
  NS constant
  ------------------------------------------------------------
*/
export type NSType = 'SETTINGS';
export const NS: NSType = 'SETTINGS';

/*
  ------------------------------------------------------------
  Types
  ------------------------------------------------------------
*/

export interface State {
  notifications: NotificationSettings | undefined
}

export interface NotificationSettings {
  fatline: FatlineSettings
  dailyCoach: DailyCoachSettings
}

export type FatlineSettings = {
  [K in FatlineAlert]: FatlineAlertSettings
}

export interface FatlineAlertSettings {
  email: boolean
  sms: boolean
}

export type FatlineTransmitter = keyof FatlineAlertSettings;

export interface DailyCoachSettings {
  enabled: boolean,
  hour: Hour,
  days: DayOfWeek[]
}

export type FatlineAlert
  = 'newInboxMessage'
  | 'gotProps'
  | 'commentPosted'
  | 'commentReplyPosted'
  | 'apptConfirmation'
  | 'apptCancellation'
  | 'apptReminder';
/*
  ------------------------------------------------------------
  Lenses
  ------------------------------------------------------------
*/

export interface Lenses {
  notificationSettings: R.Lens,
  fatlineAlert(alert: FatlineAlert, transmitter: FatlineTransmitter): R.Lens,
  dailyCoachSettings: R.Lens
}


export function makeLenses(root?: string): Lenses {
  const notificationSettings = addRoot(root, R.lensProp('notifications'));
  const fatlineSettings = R.compose(
    notificationSettings, R.lensProp('fatline')
  ) as R.Lens;

  return {
    notificationSettings,
    dailyCoachSettings: R.compose(
      notificationSettings, R.lensProp('dailyCoach')
    ) as R.Lens,
    fatlineAlert(a: FatlineAlert, t: FatlineTransmitter): R.Lens {
      return R.compose(
        fatlineSettings,
        R.lensPath([ a, t ])
      ) as R.Lens;
    }
  };
};

export const LENSES: Lenses = makeLenses();


/*
  ------------------------------------------------------------
  Creating Data
  ------------------------------------------------------------
*/

export function initialState(): State {
  return {
    notifications: undefined
  };
};

/*
  ------------------------------------------------------------
  Other helper functions etc
  ------------------------------------------------------------
*/

export function fatlineAlertLabel(alert: FatlineAlert): string {
  switch(alert) {
  case 'newInboxMessage':
    return 'sends me a message';
  case 'gotProps':
    return 'gives me props';
  case 'commentPosted':
    return 'comments on my activity';
  case 'commentReplyPosted':
    return 'responds to my comment';
  case 'apptConfirmation':
    return 'confirmations';
  case 'apptCancellation':
    return 'cancellations';
  case 'apptReminder':
    return 'reminders';
  }
}
