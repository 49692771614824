import * as React from 'react';
import * as Redux from 'redux';

import { defaultConnect } from 'Shared/ReduxComponent';

import * as List from 'misc/Data/List';
import { State } from 'App/Data';
import { SEL as UserSEL, ActionCreators as UserAC } from 'User';
import { Quicklink } from 'Home/Data';
import { ActionCreators } from 'Home/ActionCreator';

/* import Logo from 'Shared/UI/Logo';*/
import BackgroundImage from 'Shared/UI/BackgroundImage';
import Spinner from 'Shared/UI/Spinner';
import QuicklinkLink from 'Home/UI/QuicklinkLink';

import { UI as NavUI } from 'Nav';

type Props = State & {
  dispatch: Redux.Dispatch<State>
}

class HomePage extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.dispatch(UserAC.loadCurrentUser());
    this.props.dispatch(ActionCreators.loadQuicklinks());
  }

  userName(): string {
    return UserSEL.userName(this.props.currentUser) || 'friend';
  }

  quicklinks(): Quicklink[] | undefined {
    return this.props.home.quicklinks;
  }

  postPageLabel(): string {
    const program = this.props.currentUser.program;
    return program ? program.postPageLabel : 'Post Activity';
  }

  logoUrl(): string | undefined {
    return UserSEL.programLogoUrl(this.props.currentUser);
  }

  backgroundImageUrl(): string | undefined {
    return UserSEL.programBackgroundImageUrl(this.props.currentUser);
  }

  currentUserIsLoaded(): boolean {
    return this.props.currentUser.program !== undefined;
  }

  render() {
    const postPageLabel = this.postPageLabel();
    const quicklinks = this.quicklinks();
    return (
      <div className="home">
        {this.currentUserIsLoaded() &&
         <BackgroundImage src={this.backgroundImageUrl()} />
        }
        <NavUI.NavMenu
          modifier="home"
          postPageLabel={postPageLabel}
          navState={this.props.nav} dispatch={this.props.dispatch}
          appFeatures={UserSEL.appFeatures(this.props.currentUser)}
        />
        <div className="home__logo">
          {this.logoUrl() &&
           <img className="home__logo-img" src={this.logoUrl()} />
          }
        </div>
        <div className="home__welcome">
          Welcome, {this.userName()}!
        </div>
        {quicklinks ? renderQuicklinks(quicklinks) : <Spinner />}
      </div>
    );
  }
}

export default defaultConnect(HomePage);

function renderQuicklinks(quicklinks: Quicklink[]): React.ReactNode {
  return (
    <div>
      <h4 className="home__ql-title">Quick Links</h4>

      <div className="home__ql-row">
        {renderQuicklink(List.lookup(quicklinks, 0))}
        {renderQuicklink(List.lookup(quicklinks, 1))}
      </div>
      <div className="home__ql-row">
        {renderQuicklink(List.lookup(quicklinks, 2))}
        {renderQuicklink(List.lookup(quicklinks, 3))}
      </div>
    </div>
  );
}

function renderQuicklink(ql: Quicklink | undefined): React.ReactNode {
  if (ql) {
    return <QuicklinkLink quicklink={ql} />;
  }
}
