import * as R from 'ramda';
import * as moment from 'moment-timezone';
import { Task } from 'Tasks/Data';
import { ExpandedSpecType } from 'Specs';
import { Loadable, loadable } from 'misc/Data/Loadable';
import { Maybe } from 'misc/Data/Maybe';
export type NSType = 'CARE_PLANS';
export const NS: NSType = 'CARE_PLANS';

/*============================================================*/

export interface State {
  // For daily care tasks page, this stores the current "date" and the set of
  // action statuses that have been loaded for that date.  If it is Nothing,
  // that indicates there is no care plan
  dailyStatus: Loadable<Maybe<DailyStatus>>
}

export function initialState(): State {
  return { dailyStatus: loadable() };
}
/*============================================================*/

export type ActionId = number;

export interface Action {
  id: ActionId
  details: ActionDetails
  frequency: Frequency
}

export enum Frequency {
  DAILY = 'daily',
  DAILY_AM = 'daily_am',
  DAILY_PM = 'daily_pm',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly'
}

export type ActionDetails
  = TaskActionDetails
  | MeasurementActionDetails;

export enum ActionType {
  TASK = 'Task',
  MEASUREMENT = 'Measurement'
}

export interface TaskActionDetails {
  type: ActionType.TASK
  task: Task
}

export interface MeasurementActionDetails {
  type: ActionType.MEASUREMENT,
  specType: ExpandedSpecType
}

export interface ActionStatus {
  action: Action
  complete: boolean
  text: string
}

export interface DailyStatus {
  date: moment.Moment
  statuses: ActionStatus[]
}

/*------------------------------------------------------------*/

export function totalTasks(status: DailyStatus): number {
  return status.statuses.length;
}

export function completedTasks(status: DailyStatus): number {
  return R.filter(R.prop('complete'), status.statuses).length;
}
