import * as React from 'react';
import { defaultConnect } from 'Shared/ReduxComponent';
import { State, DispatchProps } from 'Store';
import { Helmet } from 'react-helmet';

type Props = State & DispatchProps;

const DEFAULT_TITLE = '1bios | realtime health engagement platform and apps';
export const DEFAULT_FAVICON = require('images/site-icon/favicon.ico').default;

const HTMLHead = (props: Props) => {
  const program = props.currentUser.program;

  if (program) {
    let title: string;
    let favicon: string | undefined;
    if (program.whiteLabeled) {
      title = program.htmlTitle;
    } else {
      title = DEFAULT_TITLE;
      favicon = DEFAULT_FAVICON;
    }
    return (
      <Helmet>
        <title>{title}</title>
        {favicon && <link rel="shortcut icon" href={favicon} />}
      </Helmet>
    );
  } else {
    return <></>;
  }
};

export default defaultConnect(HTMLHead);
