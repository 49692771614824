import * as React from 'react';
import * as moment from 'moment-timezone';
import * as classnames from 'classnames';
import * as R from 'ramda';
import { bem } from 'css-util';
import {
  Metric, MetricKey, TrendWindow, formatPeriod, TrendData, Dataset,
  calculateAverageForDataset, calculateAveragesForBloodPressure,
  calculateAverageForSteps, formatValueWithMetricKey, calculateMinForDataset,
  calculateMinForBloodPressure, calculateMaxForDataset,
  calculateMaxForBloodPressure, systolicDs, diastolicDs, BloodPressure
} from '1bios/UserTrends/Data';
import { Maybe } from 'misc/Data/Maybe';
import * as M from 'misc/Data/Maybe';
import NoContent from 'misc/UI/NoContent';

interface Props {
  availableMetrics: Metric[]
  selectedMetric: Maybe<Metric>,
  selectedWindow: TrendWindow,
  selectedDate: moment.Moment,
  currentData: Maybe<TrendData>,
  onMetricSelected: (key: MetricKey) => void
  onWindowSelected: (w: TrendWindow) => void
  gotoNext: () => void
  gotoPrevious: () => void
  metricSelectClasses?: string
}

const TrendsControls = (props: Props) => {
  return (
    <div className="trends-controls">
      <div className="trends-controls__metric-selector">
        {renderMetricSelect(props)}
      </div>

      {M.maybe(
         renderNoMetricSelected,
         renderControls(props),
         props.selectedMetric
      )
      }
    </div>
  );
};

export default TrendsControls;

function renderMetricSelect(props: Props): JSX.Element {
  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    props.onMetricSelected(e.target.value as MetricKey);
  }
  const classes = props.metricSelectClasses || 'select select--block';
  const availableMetrics = props.availableMetrics;
  return (
    <select className={classes} onChange={onChange}
      value={M.map(m => m.key, props.selectedMetric).value}>
      {availableMetrics.map(
         (m, i) => <option key={i} value={m.key.toString()}>{m.label}</option>)}
    </select>
  );
}

function renderNoMetricSelected(): JSX.Element {
  return (
    <NoContent>
      Select data to graph from the drop down.
    </NoContent>
  );
}

const renderControls = (props: Props) => (_metric: Metric) => {
  return (
    <div className="trends-controls__period">
      <div className="trends-controls__window-selector">
        {windowButton(props, 'week', TrendWindow.WEEK, 'first')}
        {windowButton(props, 'month', TrendWindow.MONTH)}
        {windowButton(props, 'year', TrendWindow.YEAR, 'last')}
      </div>

      {dateSelector(props)}
      {M.maybe(
         () => (<span></span>),
         renderAggregates(props.selectedWindow, props.selectedDate),
         props.currentData)
      }
    </div>
  );
}

const WINDOW_BTN_CSS = bem('trends-controls')('window-link');
function windowButton(
  props: Props, label: string, window: TrendWindow, modifier?: string
): JSX.Element {
  const classes = classnames(
    WINDOW_BTN_CSS([modifier]),
    'theme__primary-color',
    'theme__primary-bg-when-active',
    { 'active': props.selectedWindow === window }
  );
  const onClick = () => props.onWindowSelected(window);
  return (
    <a className={classes} onClick={onClick}>
      {label}
    </a>
  );
}

function dateSelector(props: Props): JSX.Element {
  return (
    <div className="trends-controls__date">
      <a className="trends-controls__date-btn theme__primary-color"
        onClick={props.gotoPrevious}>
    &#x25c0;&#xFE0E;
      </a>
      <span className="trends-controls__date-label">
        {formatPeriod(props.selectedDate, props.selectedWindow)}
      </span>
      <a className="trends-controls__date-btn theme__primary-color"
        onClick={props.gotoNext}>
    &#x25b6;&#xFE0E;
      </a>
    </div>
  );
}

const renderAggregates = R.curry(_renderAggregates);
function _renderAggregates(
  selectedWindow: TrendWindow, selectedDate: moment.Moment, data: TrendData
): JSX.Element {
  if (R.all(ds => R.isEmpty(ds.points), data.datasets)) {
    return (<span></span>)
  } else {
    const minForDisplay = selectedMin(data.datasets);
    const averageForDisplay =
      selectedAverage(data.datasets, selectedWindow, selectedDate);
    const maxForDisplay = selectedMax(data.datasets);
    return (
      <div className="trends-controls__aggregates">
          <div>
            Min: {minForDisplay}
          </div>
          <div>
            Average: {averageForDisplay}
          </div>
          <div>
            Max: {maxForDisplay}
          </div>
      </div>
    );
  }
}

function selectedAverage(
  datasets: Dataset[], selectedWindow: TrendWindow, selectedDate: moment.Moment
): string {
  const ds = datasets[0];
  const metricKey = ds.key;

  switch(ds.key) {
    case MetricKey.DIASTOLIC_BP:
    case MetricKey.SYSTOLIC_BP:
      const sys = systolicDs(datasets);
      const dia = diastolicDs(datasets);
      if (sys && dia) {
        const avgs = calculateAveragesForBloodPressure(sys, dia);
        return `${avgs.systolic} / ${avgs.diastolic}`;
      } else {
        return '';
      }
    case MetricKey.STEPS:
      return formatValueWithMetricKey(
        metricKey, calculateAverageForSteps(ds, selectedWindow, selectedDate)
      );
    case MetricKey.SLEEP:
    case MetricKey.BODY_TEMP:
      return formatValueWithMetricKey(
        metricKey, calculateAverageForDataset(ds, { round: false })
      )
    default:
      return formatValueWithMetricKey(
        metricKey, calculateAverageForDataset(ds)
      );
  }
}

function selectedMin(datasets: Dataset[]): JSX.Element {
  const ds = datasets[0];
  const metricKey = ds.key;

    switch(ds.key) {
      case MetricKey.DIASTOLIC_BP:
      case MetricKey.SYSTOLIC_BP:
        const sys = systolicDs(datasets);
        const dia = diastolicDs(datasets);
        if (sys && dia) {
          const sysMin = calculateMinForBloodPressure(sys, dia, 'systolic');
          const diaMin = calculateMinForBloodPressure(sys, dia, 'diastolic');
          return renderBPMinMax(sysMin, diaMin);
        } else {
          return(<span></span>);
        }
      case MetricKey.SLEEP:
      case MetricKey.BODY_TEMP:
        return(
          <span>
            {formatValueWithMetricKey(
              metricKey, calculateMinForDataset(ds, { round: false })
            )}
          </span>
          );
      default:
        return(
          <span>
            {formatValueWithMetricKey(metricKey, calculateMinForDataset(ds))}
          </span>
        );
  }
}

function selectedMax(datasets: Dataset[]): JSX.Element {
  const ds = datasets[0];
  const metricKey = ds.key;

    switch(ds.key) {
      case MetricKey.DIASTOLIC_BP:
      case MetricKey.SYSTOLIC_BP:
        const sys = systolicDs(datasets);
        const dia = diastolicDs(datasets);
        if (sys && dia) {
          const sysMax = calculateMaxForBloodPressure(sys, dia, 'systolic');
          const diaMax = calculateMaxForBloodPressure(sys, dia, 'diastolic');
          return renderBPMinMax(sysMax, diaMax);
        } else {
          return(<span></span>);
        }
      case MetricKey.SLEEP:
      case MetricKey.BODY_TEMP:
        return(
          <span>
            {formatValueWithMetricKey(
              metricKey, calculateMaxForDataset(ds, { round: false })
            )}
          </span>
          );
      default:
        return(
          <span>
            {formatValueWithMetricKey(
              metricKey, calculateMaxForDataset(ds)
            )}
          </span>
        );
  }
}

function renderBPMinMax(
  sysMinMax: BloodPressure, diaMinMax: BloodPressure
): JSX.Element {
  return(
    <span>
      <span className="trends-controls__aggregates-minmax">
        {sysMinMax.systolic}
      </span> / {sysMinMax.diastolic},{' '}
      {diaMinMax.systolic} /
      <span className="trends-controls__aggregates-minmax">{' '}
        {diaMinMax.diastolic}
      </span>
    </span>
  );
}
